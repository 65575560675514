import { IMessage } from '../reducers/appSlice';
import { AppDispatch } from '../store';

import { ActionCreators } from '../store';
const { showMessagePerm, hideMessage } = ActionCreators;

// Для того, чтобы сообщения могли выводиться друг за другом, и последнее "висело" 3 сек, каждый раз при выводе нового, таймер сбрасывается
let timer: NodeJS.Timeout;
export const showMessage = (data: IMessage) => async (dispatch: AppDispatch) => {
  clearTimeout(timer);
  dispatch(showMessagePerm(data));
  timer = setTimeout(() => dispatch(hideMessage()), 3000);
};
