import { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import _ from 'underscore';

import * as C from '../../ui/Styled';
import MainComponent from '../MainComponent';
import ChatList from '../ChatList';
import Title from './Title';
import { theme } from '../../../styles/theme';

import useSwipe from '../../../hooks/swipe.hook';
import { useAppSelector, useActions } from '../../../hooks/redux.hook';

export default function ChatDrawer() {
  const isDefaultScreen = useMediaQuery(theme.media.default);
  const { hideChatDrawer } = useActions();
  const { role } = useAppSelector((state) => state.user.userData);
  // Без isEqual происходит постоянный ререндер всего компонента чата для менеджера
  // ! Нужно ли тут _isEqual?
  const { chatDrawerVisible } = useAppSelector((state) => state.app, _.isEqual);
  const { chosenRoom } = useAppSelector((state) => state.chat);

  const [mobileChatListVisible, setMobileChatListVisible] = useState(false);

  // ----- Изменение размеров окна чата (для мобильной версии) ------
  // Задание высоты окна. По умолчанию - 250px
  const [chatDrawerHeight, setChatDrawerHeight] = useState<number>(250);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  // Обработка событий через кастомный хук
  const { handleTouchStart, handleTouchMove, handleTouchEnd, yOffset, touchEnd } = useSwipe();

  // Привязка к ref заголовка окна чата
  const chatHeader = useRef<HTMLElement | null>(null);

  // При изменении видимости окна к ref привязывается заголовок окна чата и к нему привязываются обработчики событий
  useEffect(() => {
    if (chatDrawerVisible) {
      chatHeader.current = document.querySelector('.chat-drawer-mobile .ant-drawer-header');
      // ! Подумать над типизацией e!
      chatHeader.current?.addEventListener('touchstart', (e: any) => handleTouchStart(e));
      chatHeader.current?.addEventListener('touchmove', (e: any) => handleTouchMove(e));
      chatHeader.current?.addEventListener('touchend', () => handleTouchEnd());

      // высота окна определяется как заданная в стейте плюс изменение, пришедшее из кастомного хука. '|| 0' прописано, т.к. при первом рендере yOffset = NaN.
      const height = chatDrawerHeight + (yOffset || 0);

      setChatDrawerHeight(height);
    }
  }, [chatDrawerVisible, touchEnd]); // eslint-disable-line react-hooks/exhaustive-deps

  // -----------------------------------------------------------------

  return (
    <Drawer
      visible={chatDrawerVisible}
      title={
        <Title
          chosenRoom={chosenRoom}
          mobileChatListVisible={mobileChatListVisible}
          setMobileChatListVisible={setMobileChatListVisible}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
        />
      }
      className={isDefaultScreen ? 'chat-drawer' : 'chat-drawer-mobile'}
      closable={true}
      closeIcon={
        <CloseCircleOutlined style={{ color: theme.colors.text.main, fontSize: '1.3rem' }} />
      }
      onClose={() => {
        hideChatDrawer();
      }}
      // Уничтожаем компонент при скрытии для покупателя. Сделано для того, чтобы можно было открывать разные окна с чатами. Менеджер будет всегда пользоваться одним и тем же компонентом. В этом случае ему всегда смогут писать, и сообщения будут приходить даже когда кнопка не нажата
      destroyOnClose={role === 'customer' ? true : false}
      mask={false}
      bodyStyle={{ padding: '10px' }}
      headerStyle={{
        backgroundColor: theme.colors.background.main,
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
      placement="bottom"
      contentWrapperStyle={
        isDefaultScreen
          ? {
              height: '50vh',
              marginTop: '50vh',
              width: role === 'admin' || role === 'manager' ? '50vw' : '30vw',
              right: '1%',
              bottom: '3%',
            }
          : {
              width: '100%',
              height: fullScreen
                ? `${document.documentElement.clientHeight}px`
                : `${chatDrawerHeight}px`,
            }
      }
    >
      <C.Flex
        $center
        $height="100%"
        //! убрать класс
        // className="flex-center-wrapper h100"
        // Предотвращение скролла заднего фона при свайпе внутри дроера чата. Именно такая комбинация позволяет отдельно скроллить (пальцем) чат и задний фон. Но при скролле чата все равно возникают короткие сдвиги заднего фона. stopPropagation и применение всех этих ухищрений дополнительно к onTouchMove ни к чему не приводят.
        onTouchStart={(e: React.TouchEvent<Element>) => {
          document.body.classList.add('scroll-cancel');
        }}
        onTouchEnd={(e: React.TouchEvent<Element>) => {
          document.body.classList.remove('scroll-cancel');
        }}
      >
        {/* Для не покупателя показывается список кнопок с чатами (комнатами). Каждая комната добавляется при заходе Покупателя в чат */}
        {role !== 'customer' && isDefaultScreen ? <ChatList /> : null}
        {mobileChatListVisible ? <ChatList /> : <MainComponent />}
      </C.Flex>
    </Drawer>
  );
}
