import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { IMessage } from '../../redux/reducers/appSlice';
import { ActionCreators } from '../../redux/store';

let timer: NodeJS.Timeout;
// Отображение сообщения с последующим скрытием
export const showMessageWithHiding = (api: BaseQueryApi, data: IMessage) => {
  clearTimeout(timer);
  api.dispatch(ActionCreators.showMessagePerm(data));
  timer = setTimeout(() => api.dispatch(ActionCreators.hideMessage()), 3000);
};

export const userLogoutHandler = (api: BaseQueryApi) => {
  localStorage.removeItem('token');
  api.dispatch(ActionCreators.userSetInitialState());
  api.dispatch(ActionCreators.hideChatDrawer());
  api.dispatch(ActionCreators.hideLoader());
  return { data: null };
};
