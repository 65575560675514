import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMessage {
  text: string;
  type: 'success' | 'danger' | 'warning' | '';
  topic?: string;
  data?: any;
}

export interface AppState {
  isLoading: boolean;
  message: IMessage;
  chatDrawerVisible: boolean;
  searchedValue: string;
}

const initialState: AppState = {
  isLoading: false,
  message: { text: '', type: '', topic: '', data: '' },
  chatDrawerVisible: false,
  searchedValue: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
    showMessagePerm: (state, action: PayloadAction<IMessage>) => {
      state.message = action.payload;
    },
    hideMessage: (state) => {
      state.message = initialState.message;
    },
    showChatDrawer: (state) => {
      state.chatDrawerVisible = true;
    },
    hideChatDrawer: (state) => {
      state.chatDrawerVisible = false;
    },
    setSearchedValueToStore: (state, action: PayloadAction<string>) => {
      state.searchedValue = action.payload;
    },
  },
});

export const { actions, reducer } = appSlice;
