import { baseQuerySliceWithRefreshToken } from './baseQuerySlices';

import { ILogin } from '../../types/user';
import { IUserRegForm } from '../../pages/UsersPage/types';

const authAPI = baseQuerySliceWithRefreshToken.injectEndpoints({
  endpoints: (builder) => ({
    userLogin: builder.mutation<ILogin, { email: string; password: string; fingerprint: string }>({
      query: (userLoginData) => ({
        url: 'auth/login',
        method: 'POST',
        body: userLoginData,
      }),
    }),
    userLogout: builder.mutation<{ message: string }, string>({
      query: (fingerprint) => ({ url: `auth/logout/${fingerprint}`, method: 'GET' }),
    }),
    userRegister: builder.mutation<{ message: string }, IUserRegForm>({
      query: (userRegData) => ({
        url: 'auth/register',
        method: 'POST',
        body: userRegData,
      }),
      invalidatesTags: ['SyncGrid'],
    }),
    userRefreshToken: builder.query<ILogin, string>({
      query: (fingerprint) => ({
        url: `auth/refreshtokens/${fingerprint}`,
      }),
    }),
    userPasswordForgot: builder.mutation<{ message: string }, string>({
      query: (email) => ({
        url: `auth/passforgot`,
        method: 'POST',
        body: { email },
      }),
    }),
    userPasswordReset: builder.mutation<
      { message: string },
      { resetPasswordToken: string; password: string }
    >({
      query: ({ resetPasswordToken, password }) => ({
        url: `auth/passreset`,
        method: 'POST',
        body: { resetPasswordToken, password },
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useUserLogoutMutation,
  useUserRegisterMutation,
  useLazyUserRefreshTokenQuery,
  useUserPasswordForgotMutation,
  useUserPasswordResetMutation,
} = authAPI;
