import { configureStore } from '@reduxjs/toolkit';

import { baseQuerySliceWithRefreshToken } from '../services/rtkQuery/baseQuerySlices';

import { actions as appActionCreators, reducer as appReducer } from './reducers/appSlice';
import { actions as userActionCreators, reducer as userReducer } from './reducers/userSlice';
import { actions as dataActionCreators, reducer as dataReducer } from './reducers/dataSlice';
import { actions as chatActionCreators, reducer as chatReducer } from './reducers/chatSlice';

export const store = configureStore({
  reducer: {
    [baseQuerySliceWithRefreshToken.reducerPath]: baseQuerySliceWithRefreshToken.reducer,
    app: appReducer,
    user: userReducer,
    data: dataReducer,
    chat: chatReducer,
  },
  // ингор предупреждений, связанных с сокетами
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   // Ignore these action types
      //   ignoredActions: [
      //     'chat/setManagerSocket',
      //     'chat/setRoomsData',
      //     'chat/setInitialMessages',
      //     'chat/addChatMessage',
      //   ],
      //   // Ignore these field paths in all actions
      //   ignoredActionPaths: ['chat.managerSocket', 'payload.timestamp'],
      //   // Ignore these paths in the state
      //   ignoredPaths: ['chat.managerSocket'],
      // },
    }).concat(baseQuerySliceWithRefreshToken.middleware),
});

export const ActionCreators = {
  ...appActionCreators,
  ...userActionCreators,
  ...dataActionCreators,
  ...chatActionCreators,
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
