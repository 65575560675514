import { useMediaQuery } from 'react-responsive';
import {
  UnorderedListOutlined,
  LeftOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from '@ant-design/icons';

import * as C from '../../../ui/Styled';
import { useAppSelector } from './../../../../hooks/redux.hook';

import { theme } from '../../../../styles/theme';
interface ITitleProps {
  chosenRoom: string | null;
  mobileChatListVisible: boolean;
  setMobileChatListVisible: (visibility: boolean) => void;
  fullScreen: boolean;
  setFullScreen: (fullScreen: boolean) => void;
}

export default function Title({
  chosenRoom,
  mobileChatListVisible,
  setMobileChatListVisible,
  fullScreen,
  setFullScreen,
}: ITitleProps) {
  const isDefaultScreen = useMediaQuery(theme.media.default);
  const { role } = useAppSelector((state) => state.user.userData);

  // Из названия выбранной комнаты (если выбрана) извлекаем номер пользователя и заказа (для отображения в заголовке чата для менеджера)
  let userId: string = '',
    orderId: string = '';
  if (chosenRoom) {
    userId = chosenRoom?.split('-')[0];
    orderId = chosenRoom?.substr(userId.length + 1, chosenRoom?.length);
  }

  const ChatListIcon = ({ text }: { text: string }) => (
    <>
      <UnorderedListOutlined
        style={{ paddingRight: '15px', fontSize: '17px' }}
        onClick={() => setMobileChatListVisible(true)}
      />
      {text}
      {fullScreen ? (
        <FullscreenExitOutlined
          style={{ paddingRight: '15px', fontSize: '20px' }}
          onClick={() => setFullScreen(false)}
        />
      ) : (
        <FullscreenOutlined
          style={{ paddingRight: '15px', fontSize: '20px' }}
          onClick={() => setFullScreen(true)}
        />
      )}
    </>
  );

  const BackToChatIcon = ({ text }: { text: string }) => (
    <>
      <LeftOutlined
        style={{ paddingRight: '15px', fontSize: '17px' }}
        onClick={() => setMobileChatListVisible(false)}
      />
      {text}
      {fullScreen ? (
        <FullscreenExitOutlined
          style={{ paddingRight: '15px', fontSize: '20px' }}
          onClick={() => setFullScreen(false)}
        />
      ) : (
        <FullscreenOutlined
          style={{ paddingRight: '15px', fontSize: '20px' }}
          onClick={() => setFullScreen(true)}
        />
      )}
    </>
  );

  const FullScreenIcon = ({ text }: { text: string }) => (
    <>
      {text}
      {fullScreen ? (
        <FullscreenExitOutlined
          style={{ paddingRight: '15px', fontSize: '20px' }}
          onClick={() => setFullScreen(false)}
        />
      ) : (
        <FullscreenOutlined
          style={{ paddingRight: '15px', fontSize: '20px' }}
          onClick={() => setFullScreen(true)}
        />
      )}
    </>
  );

  let titleMessage: string | JSX.Element = '';
  // Не Покупатель, Большой экран
  if (isDefaultScreen && role !== 'customer') {
    titleMessage = 'Чат с клиентами';
  }
  if (isDefaultScreen && chosenRoom && orderId === 'general') {
    titleMessage = `Чат с клиентом ${userId} по общим вопросам`;
  }
  if (isDefaultScreen && chosenRoom && orderId !== 'general' && isDefaultScreen) {
    titleMessage = `Чат с клиентом ${userId} по заказу ${orderId}`;
  }
  // Покупатель, Большой экран
  if (isDefaultScreen && role === 'customer') {
    titleMessage = 'Задайте вопрос';
  }
  //  Не Покупатель, Мобильная версия
  // Список чатов закрыт
  if (!isDefaultScreen && role !== 'customer' && !chosenRoom && !mobileChatListVisible) {
    titleMessage = (
      <C.Flex $justify="space-between" $align="center">
        <ChatListIcon text="Чат с клиентами" />
      </C.Flex>
    );
  }
  if (!isDefaultScreen && chosenRoom && orderId !== 'general' && !mobileChatListVisible) {
    titleMessage = (
      <C.Flex $justify="space-between" $align="center">
        <ChatListIcon text={`Чат с клиентом ${userId} по заказу ${orderId}`} />
      </C.Flex>
    );
  }
  if (!isDefaultScreen && orderId === 'general' && !mobileChatListVisible) {
    titleMessage = (
      <C.Flex $justify="space-between" $align="center">
        <ChatListIcon text={`Чат с клиентом ${userId} по общим вопросам`} />
      </C.Flex>
    );
  }
  // Список чатов открыт
  if (!isDefaultScreen && mobileChatListVisible) {
    titleMessage = (
      <C.Flex $justify="space-between" $align="center">
        <BackToChatIcon text={'Выберите чат'} />
      </C.Flex>
    );
  }
  //    Покупатель, Мобильная версия
  if (!isDefaultScreen && role === 'customer') {
    titleMessage = (
      <C.Flex $justify="space-between" $align="center">
        <FullScreenIcon text={'Задайте вопрос'} />
      </C.Flex>
    );
  }

  return <>{titleMessage}</>;
}
