import { baseQuerySliceWithRefreshToken } from './baseQuerySlices';

import { IMessage } from '../../types/chat';

const chatAPI = baseQuerySliceWithRefreshToken.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllChatMessages: builder.query<IMessage[], { akspromUserId: string; room: string }>({
      query: ({ akspromUserId, room }) => ({
        url: `chat?akspromUserId=${akspromUserId}&room=${room}`,
      }),
      providesTags: (result) => ['Chat'],
    }),
    setMessagesViewed: builder.mutation<void, { akspromUserId: string; room: string }>({
      query: ({ akspromUserId, room }) => ({
        url: `chat?akspromUserId=${akspromUserId}&room=${room}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useLazyFetchAllChatMessagesQuery, useSetMessagesViewedMutation } = chatAPI;
