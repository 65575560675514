import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { useSearchedValue } from '../../hooks/searchedValueInput.hook';
import { useActions } from '../../hooks/redux.hook';

interface InputWithSearchProps {
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  inputWidth?: string;
  tooltipArrowPointAtCenter?: boolean;
  dontSaveToState?: boolean;
  sendSearchedValueToComponent?: (searchedValue: string) => void;
}

// Поисковая строка. Для записи введенного значения в стор используется хук useSearchedValue. Значение записывается через заданное в хуке время. Пропсами задается видимость тултипа, его параметры и ширина инпута. Пропс dontSaveToState запрещает записывать значение в глобальный стейт. Это необходимо при использовании инпута в конкретных компоенентах, для того, чтобы при поиске в них не реренделись остальные компоненты с новым результатом поиска
export default function InputWithSearch({
  tooltipText,
  tooltipPlacement,
  tooltipArrowPointAtCenter,
  inputWidth = '100%',
  dontSaveToState = false,
  sendSearchedValueToComponent,
}: InputWithSearchProps) {
  const { setSearchedValueToStore } = useActions();
  // Хук поиска заказов по строке, введенной в инпут
  const { searchedValue, inputHandler, searchTimer } = useSearchedValue();

  // Очистка инпута при переключении страницы. Только для инпутов на страницах (т.е. нет пропса dontSaveToState)
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (!dontSaveToState) {
      setInputValue('');
      setSearchedValueToStore('');
    }
  }, [useLocation().pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  // В том случае, если поисковая строка используется в конкретном компоненте (например в карточке заказа), необходимо, чтоб она не записывалась в глобальной стор (чтоб не обновлялся поиск на странице под дроером). В этом случае она передается в стейт самого компонента
  useEffect(() => {
    if (dontSaveToState && sendSearchedValueToComponent) {
      sendSearchedValueToComponent(searchedValue);
    }
  }, [searchedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    inputHandler(e, searchTimer, dontSaveToState);
  };

  return (
    <>
      {tooltipText ? (
        <Tooltip
          title={tooltipText}
          placement={tooltipPlacement}
          arrowPointAtCenter={tooltipArrowPointAtCenter}
        >
          <Input
            placeholder="Поиск..."
            onChange={onChangeInputHandler}
            style={{ width: inputWidth }}
            value={inputValue}
          />
        </Tooltip>
      ) : (
        <Input
          placeholder="Поиск..."
          onChange={onChangeInputHandler}
          style={{ width: inputWidth }}
          value={inputValue}
        />
      )}
    </>
  );
}
