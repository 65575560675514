// import FingerprintJS from '@fingerprintjs/fingerprintjs';
import FingerprintJSPro from '@fingerprintjs/fingerprintjs-pro';

// Создание fingerprint в двух вариантах: использование бесплатной библиотеки и ее платной версии. У платной более точное создание ID и возможность распознаваня режима инкогнито браузера. Но только 20000 запросов в месяц. Пока используется платная версия. Код для бесплатного варианта закомментирован
export default async function getFingerprint() {
  // Если fingerprint уже записан в LocalStorage, то он и возвращается
  const fingerprint = localStorage.getItem('fingerprint');
  if (fingerprint) {
    return fingerprint;

    // Иначе создается новый
  } else {
    // Инициализаця библиотеки
    // const fpPromise = FingerprintJS.load();
    const fpPromisePro = FingerprintJSPro.load({
      token: 'DNI2JK5uxiEelMJa9fEC',
      region: 'eu',
    });

    // Get the visitor identifier when you need it.
    // const fp = await fpPromise;
    const fpPro = await fpPromisePro;

    // const result = await fp.get();
    const resultPro = await fpPro.get({ extendedResult: true });

    // This is the visitor identifier:
    // const visitorId = result.visitorId;
    const visitorIdPro = resultPro.visitorId;

    // Если браузер в режиме инкогнито, то возвращается 'incognito'. При этом на сервере запрещается создание refreshToken
    if (resultPro.incognito) {
      return 'incognito';

      // Если браузер в обычном режиме, то новый fingerprint сохраняется в LocalStorage и возвращается
    } else {
      localStorage.setItem('fingerprint', visitorIdPro);
      return visitorIdPro;
    }
  }
}
