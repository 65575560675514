import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  from {
    transform: scale(.85);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(.85);
  }
`;

export const LogoMainWithoutTextAnimated = styled.div`
    height: 200px;
    width: 200px;
    background: url('/images/logo/logo_main_without_text.png') center center/60% no-repeat;
    animation: 2s ${pulse} ease-in-out infinite;
`;
