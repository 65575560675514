import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRole } from '../../models/IUser';

import { IColumns } from '../../types/data';
import { IMailSettings } from '../../types/mail';

interface DataState {
  colNames: { all: IColumns; default: { [role in IRole]: IColumns } };
  mailSettings: IMailSettings;
}

const initialState: DataState = {
  colNames: {
    all: {} as IColumns,
    default: {} as { [role in IRole]: IColumns },
  },
  mailSettings: {} as IMailSettings,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    // Список вех столбцов (подгружается из БД после авторизации)
    loadAllColumns: (state, action: PayloadAction<IColumns>) => {
      state.colNames.all = action.payload;
    },
    // Список столбцов по умолчанию (подгружается из БД после авторизации). Задается в FieldPage для каждой роли отдельно
    loadDefautColumns: (state, action: PayloadAction<{ [role in IRole]: IColumns }>) => {
      state.colNames.default = action.payload;
    },
  },
});

export const { actions, reducer } = dataSlice;
