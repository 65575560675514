import ReactEmoji from 'react-emoji';
import { Tooltip } from 'antd';

import * as S from './styles';

import { IMessage } from '../../../types/chat';
interface IMessageProps {
  message: IMessage;
  akspromUserId: string;
}

export default function Message({ message, akspromUserId }: IMessageProps) {
  let isSentBy = '';

  if (message.user.akspromUserId === akspromUserId) {
    isSentBy = 'current';
  }
  if (message.user.role === 'host') {
    isSentBy = 'host';
  }

  return isSentBy === 'current' ? (
    <S.MessageWrapper user="current">
      <Tooltip
        title={message.date.formattedDateFull}
        overlayInnerStyle={{ borderRadius: '4px' }}
        arrowPointAtCenter={true}
      >
        <S.Date user="current">{message.date.formattedDateShort}</S.Date>
      </Tooltip>
      <S.MessageBox user="current">
        <S.MessageText user="current">{ReactEmoji.emojify(message.text)}</S.MessageText>
      </S.MessageBox>
    </S.MessageWrapper>
  ) : isSentBy === 'host' ? (
    <S.MessageWrapper user="host">
      <S.MessageBox user="host">
        <S.MessageText>{message.text}</S.MessageText>
      </S.MessageBox>
    </S.MessageWrapper>
  ) : (
    <S.MessageWrapper>
      <S.MessageBox>
        <S.MessageText>{ReactEmoji.emojify(message.text)}</S.MessageText>
      </S.MessageBox>
      <Tooltip
        title={message.date.formattedDateFull}
        overlayInnerStyle={{ borderRadius: '4px' }}
        arrowPointAtCenter={true}
      >
        <S.Date>{message.date.formattedDateShort}</S.Date>
      </Tooltip>
    </S.MessageWrapper>
  );
}
