import { Layout } from 'antd';

import * as S from './styled';
import MainMenu from '../MainMenu';

interface SiderProps {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Sider({ sidebarCollapsed, setSidebarCollapsed }: SiderProps) {
  return (
    <Layout.Sider
      collapsible
      collapsed={sidebarCollapsed}
      onCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}
      // Инлайновые стили из документации для фиксирования сайдбара
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      {/* Логотип (изображение зависит от состояния сайдбара) */}
      <S.Logo collapsed={sidebarCollapsed} />

      {/* Пункты меню определены в отдельном компоненте */}
      <MainMenu theme="dark" />
    </Layout.Sider>
  );
}
