import { Route, Routes } from 'react-router-dom';

import { publicRoutes, anyUserPrivateRoutes, adminPrivateRoutes } from '../../helpers/router';
import MainLayout from '../MainLayout';
import RequireAuth from './RequireAuth';

export const AppRouter = () => {
  return (
    <Routes>
      {/* Все приватные страницы расположены внутри компонента MainLayout. Все компоненты этих маршрутов обернуты в компонент RequireAuth. В нем производится проверка пользователя на авторизацию, активацию и подходящую для данного маршрута роль. По итогу либо выводится требуемый маршрут, либо производится редирект */}
      <Route element={<MainLayout />}>
        {/* Приватные маршруты, доступные для пользователя с любой ролью */}
        {anyUserPrivateRoutes.map((route) => (
          <Route
            path={route.path}
            element={<RequireAuth>{route.element}</RequireAuth>}
            key={route.path}
          />
        ))}
        {/* Приватные маршруты, доступные для пользователя с ролью admin или manager (задается через пропс routeForRole) */}
        {adminPrivateRoutes.map((route) => (
          <Route
            path={route.path}
            element={<RequireAuth routeForRole="admin">{route.element}</RequireAuth>}
            key={route.path}
          />
        ))}
      </Route>

      {/* Публичные маршруты (авторизация, восстановление пароля, 404) */}
      {publicRoutes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}
    </Routes>
  );
};
