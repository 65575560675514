import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Socket } from 'socket.io-client';

import * as C from '../../ui/Styled';
import * as S from './styles';
import Messages from '../Messages';
import Input from '../Input';
import { theme } from '../../../styles/theme';

import { useChat } from '../../../hooks/chat.hook';
import { useAppSelector, useActions } from '../../../hooks/redux.hook';
import {
  useLazyFetchAllChatMessagesQuery,
  useSetMessagesViewedMutation,
} from '../../../services/rtkQuery/chatAPI';

import { IMessage, IUserTyping } from '../../../types/chat';

interface IMainComponentProps {
  order?: string;
}

let socket: Socket;

// order передается при открытии чата покупателем из заказа. Если не передан, значит чат открыт кнопкой на сайдбаре, т.е. это чат по общим вопросам
export default function MainComponent({ order = 'general' }: IMainComponentProps) {
  const isDefaultScreen = useMediaQuery(theme.media.default);
  const { setInitialMessages, addChatMessage } = useActions();
  const { akspromUserId, firstname, role } = useAppSelector((state) => state.user.userData);
  const { managerSocket, chosenRoom, roomsData } = useAppSelector((state) => state.chat);
  const { setSocketToRoom } = useChat();
  const [fetchAllChatMessagesQuery] = useLazyFetchAllChatMessagesQuery();
  const [setMessagesViewed] = useSetMessagesViewedMutation();

  const [message, setMessage] = useState<string>('');
  const [userTyping, setUserTyping] = useState<IUserTyping>({} as IUserTyping);

  // Название комнаты для присоединения формируется следующим образом. Если менеджер выбрал комнату из списка имеющихся, то комната попадает в chosenRoom. Если в chosenRoom ничего нет, значит эту комнату создает клиент, тогда ее название формируется как akspromUserId-akspromOrderId
  const room = chosenRoom || `${akspromUserId}-${order}`;

  // Создание socket для Покупателя при первом открытии чата (любого). При этом в стор заносится комната с привязанным к ней socket. Одновременно производится присоединение socket к room. При повторном открытии окна чата socket берется из стора (roomsData[room].socket). Для менеджера то же самое производится при выборе конкретной комнаты (в компоненте ChatList)
  useEffect(() => {
    if (role === 'customer') {
      if (roomsData[room]) {
        socket = roomsData[room].socket;

        // При открытии окна чата пользователь добавляется в список пользователей в комнате
        socket.emit('openChatWindow', { akspromUserId, firstname, role, room });
      } else {
        socket = setSocketToRoom(room);
      }
    }

    // При закрытии окна чата пользователь удаляется из списка пользователей в комнате (не дисконнектится!) для отрпавки ему уведомлений о новых сообщениях и отображения количества новых сообщений в чатах
    return () => {
      socket.emit('closeChatWindow', room);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // При смене комнаты в качестве socket утанавливается либо записанный в roomData для данной комнаты (если менеджер уже выбрал чат по кнопке), либо socket пользователя, установленный в хуке выше, либо managerSocket (созданный и записанный в стор при первом запуске приложения менеджером). managerSocket устанавливается для того, чтобы принимать данные о подключенных комнатах и поступивших сообщениях даже не открывая окна чата
  useEffect(() => {
    socket = chosenRoom ? roomsData[chosenRoom].socket : socket || managerSocket;

    // При первой загрузке компонента подгружаем из БД все сообщения
    getMessagesFromDB();

    // Отслеживание ввода текста пользователем
    socket.on('userTyping', (data: IUserTyping) => setUserTyping(data));

    // При получении нового сообщения с сервера записываем его в стор
    socket.on('message', (message: IMessage) => {
      addChatMessage({ room, message });
    });

    // При закрытии чата отключаем все слушатели
    return () => {
      socket.off('message');
      socket.off('userTyping');
    };
  }, [room]); // eslint-disable-line react-hooks/exhaustive-deps

  // Получение всех сообщений из БД и загрузка их в стор
  const getMessagesFromDB = async (): Promise<void> => {
    const allMessages = await fetchAllChatMessagesQuery({ akspromUserId, room }, true);

    let viewedMessages: IMessage[] = [];
    let notViewedMessages: IMessage[] = [];

    allMessages?.data?.forEach((message: IMessage) => {
      if (message.viewed === 1) {
        viewedMessages.push(message);
      } else {
        notViewedMessages.push(message);
      }
    });
    // }

    // Запись в стор массива сообщений из БД
    setInitialMessages({ room, viewedMessages, notViewedMessages });

    // Изменене статуса всех сообщений данного чата в viewed
    await setMessagesViewed({ akspromUserId, room });
  };

  // Отправка сообщений по кнопке в заданную комнату. После отправки инпут обнуляется
  const sendMessageHandler = (
    e: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent
  ): void => {
    e.preventDefault();

    if (message) {
      socket.emit('sendMessage', { message, room }, () => setMessage(''));
    }
  };

  // Отслеживание печатания
  const typing = (state: boolean): void => {
    socket.emit('typing', { akspromUserId, firstname, typing: state, room });
  };

  return (
    <C.Flex $direction="column" $height="100%" $width="100%">
      {/* При открытии чата Не покупателем, если еще не выбрана ни одна из комнат (не нажата соответствующая кнопка в левой части чата), то в правой части чата отображается сообщение */}
      {role !== 'customer' && !chosenRoom ? (
        <S.NoChatMessage>
          {isDefaultScreen
            ? 'При поступлении вопроса от пользователя в левом окне появится соответстующая кнопка. Нажмите на нее для начала диалога'
            : 'При поступлении вопроса от пользователя в меню выбора чатов появится соответствующая кнопка. Для доступа в меню чатов нажмите на кнопку в заголовке'}
        </S.NoChatMessage>
      ) : (
        // Для покупателя в правой части чата всегда открывается список сообщений и инпут
        <>
          <Messages room={room} akspromUserId={akspromUserId} userTyping={userTyping} />
          <Input
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessageHandler}
            typing={typing}
          />
        </>
      )}
    </C.Flex>
  );
}
