import { IOrder } from '../../models/IOrder';
import { baseQuerySliceWithRefreshToken } from './baseQuerySlices';

const orderAPI = baseQuerySliceWithRefreshToken.injectEndpoints({
  endpoints: (builder) => ({
    fetchOrdersByAkspromUserId: builder.query<IOrder[], string>({
      query: (akspromOrderId) => ({ url: `order/?akspromUserId=${akspromOrderId}` }),
    }),
    addFastOrder: builder.mutation<{ message: string }, FormData>({
      query: (fastOrderData) => ({ url: 'order', method: 'POST', body: fastOrderData }),
      invalidatesTags: ['SyncGrid'],
    }),
  }),
});

export const { useLazyFetchOrdersByAkspromUserIdQuery, useAddFastOrderMutation } = orderAPI;
