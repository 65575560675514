import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { useActions } from '../../../../../hooks/redux.hook';
import getFingerprint from '../../../../../helpers/getFingerprint';
import { useUserLogoutMutation } from '../../../../../services/rtkQuery/authAPI';

export default function DropdownMenu() {
  const { userLogout } = useActions();
  const [userLogoutQuery] = useUserLogoutMutation();

  // Разлогинивание пользователя на сервере. Если ОК, то удаление refreshToken из LocalStorage, данных о пользователе из стора, перевод параметра isAuth в false
  const userLogoutHandler = async () => {
    const fingerprint = await getFingerprint();
    const userLogoutData = await userLogoutQuery(fingerprint);

    if (!('error' in userLogoutData)) {
      userLogout();
    }
  };

  return (
    <Menu style={{ border: '1px solid #f0f0f0', borderRadius: '3px' }}>
      <Menu.Item style={{ height: '30px' }} key={nanoid()}>
        <Link to={'/orders'}>Заказы</Link>
      </Menu.Item>
      <Menu.Item style={{ height: '30px' }} key={nanoid()}>
        <Link to={'/ordercontents'}>Товары</Link>
      </Menu.Item>
      {/* <Menu.Item style={{ height: '30px' }} key={nanoid()}>
        Настройки
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item onClick={userLogoutHandler} key={nanoid()}>
        Выйти
      </Menu.Item>
    </Menu>
  );
}
