import { useState } from 'react';

import { useActions } from './redux.hook';

// Поиск заказов по строке, введенной в инпут
export const useSearchedValue = () => {
  const { setSearchedValueToStore } = useActions();
  // Введенный в инпут запрос сохраняется в стейте. Они используется в запросе, отправляемом на сервер. Каждый раз при изменении стейта отправляется новый запрос.
  const [searchedValue, setSearchedValue] = useState('');

  // Стейт таймера для поиска по строке. При изменении значения в инпуте запускается таймер, "номер" которого записывается в этот стейт. При следующем изменении этот номер передается в функцию и по нему таймер останавливается. Запускается следующий. Так просходит до окончания ввода (или пока время между введенными символами не превысит заданное в таймере)
  // Чтоб тип NodeJS.Timeout работал как number, прописал в файл d.ts type Timeout = number
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout>(0);

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    searchTimerValue: ReturnType<typeof setTimeout>,
    dontSaveToState?: boolean
  ) => {
    // Введенное значение
    const inputValue = event.target.value;
    // Таймер сбрасывается посел каждого введенного символа, для того, чтобы запрос отправлялся только по прошествии заданного времни, а не после каждого символа
    clearTimeout(searchTimerValue);

    setSearchTimer(
      setTimeout(() => {
        // Для использования инпута в компоненте
        dontSaveToState
          ? setSearchedValue(inputValue)
          : // Для использования инпута в хедере
            setSearchedValueToStore(inputValue);
      }, 300)
    );
  };

  return { searchedValue, inputHandler, searchTimer };
};
