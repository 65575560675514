import styled from 'styled-components';

interface MessageFieldProps {
  type?: string;
}

export const MessageField = styled.div<MessageFieldProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${({ type, theme }) =>
    type === 'success'
      ? theme.colors.message.background.success
      : theme.colors.message.background.danger};
  border-radius: 5px;
  border: 1px solid
    ${({ type, theme }) =>
      type === 'success'
        ? theme.colors.message.border.success
        : theme.colors.message.border.danger};
  color: ${({ type, theme }) =>
    type === 'success' ? theme.colors.message.text.success : theme.colors.message.text.danger};
  font-size: 0.9rem;
`;
