import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

interface MobileMenuBurgerProps {
  mobileMenuCollapsed: boolean;
  setMobileMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  mobileMenuCloseHandler: () => void;
}

export default function MobileMenuBurger({
  mobileMenuCollapsed,
  setMobileMenuCollapsed,
  mobileMenuCloseHandler,
}: MobileMenuBurgerProps) {
  return (
    <>
      {/* Бургер/крест в зависимости от состояния мобильного меню */}
      {mobileMenuCollapsed ? (
        <MenuOutlined
          // При клике на бургер происходит открытие мобильного меню и запрещается скролл экрана при помощи добавления класса к body
          onClick={() => {
            document.body.classList.add('scroll-cancel');
            setMobileMenuCollapsed(false);
          }}
        />
      ) : (
        <CloseOutlined onClick={mobileMenuCloseHandler} />
      )}
    </>
  );
}
