import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import { ThemeProvider } from 'styled-components';

import App from './App';
import './styles/index.scss';

// import { store } from './redux/';
import { store } from './redux/store';
import { theme } from './styles/theme';
import { GlobalStyle } from './styles/global';
import SuspenseWrapper from './components/ui/SuspenseWrapper';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SuspenseWrapper fallback="logo">
        <ConfigProvider locale={ruRu}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
          </ThemeProvider>
        </ConfigProvider>
      </SuspenseWrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
