import styled from 'styled-components';

interface WrapperProps {
  $position?: 'static' | 'absolute' | 'fixed' | 'relative';
  $top?: string;
  $left?: string;
  $right?: string;
  $bottom?: string;
  $width?: string;
  $height?: string;
  $margin?: string;
  $padding?: string;
  $overflow?: 'visible' | 'auto' | 'hidden' | 'scroll' | 'inherit';
  $zIndex?: number;
}

const Wrapper = styled.div<WrapperProps>`
  position: ${({ $position }) => $position};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
  bottom: ${({ $bottom }) => $bottom};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  margin: ${({ $margin }) => $margin};
  padding: ${({ $padding }) => $padding};
  overflow: ${({ $overflow }) => $overflow};
  z-index: ${({ $zIndex }) => $zIndex};
`;

export default Wrapper;
