import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery, baseQueryWithRefreshToken } from './baseQueries';

export const baseQuerySlice = createApi({
  baseQuery,
  endpoints: () => ({}),
});

export const baseQuerySliceWithRefreshToken = createApi({
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: ['MailSettings', 'Chat', 'SyncGrid'],
  endpoints: () => ({}),
});
