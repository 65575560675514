import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux.hook';

interface IRequireAutProps {
  children: JSX.Element;
  routeForRole?: 'any' | 'admin';
}

export default function RequireAuth({ children, routeForRole = 'any' }: IRequireAutProps) {
  const {
    isAuth,
    userData: { role, isActivated },
  } = useAppSelector((state) => state.user);
  let location = useLocation();

  // Если пользователь не авторизован и/или не активирован, происходит редирект на страницу авторизации. При этом адрес, по которому пользователь пытался перейти, сохраняется. Это позволяет в случае успешной авторизации перенаправить его на желаемую страницу
  if (!isAuth || !isActivated) {
    return <Navigate to="/" state={{ from: location }} replace />;
    // Если пользователь автризован, но его роль не соответстует требуемой для данной страницы, то производится редирект на страницу /orders
  } else if (routeForRole === 'admin' && !(role === 'admin' || role === 'manager')) {
    return <Navigate to="/orders" state={{ from: location }} replace />;
  }

  // Если все условия выполнены, то загружается требуемая страница
  return children;
}
