// ! import { IPage } from '../hooks/routes.hook';
import { IPage } from '../helpers/router';
import { IRole } from '../models/IUser';
import { ColNames, DataType } from '../types/data';

export default function translate() {
  const colNamesTranslate = (value: ColNames, type?: DataType): string => {
    // Обработка исключений (для случаев, когда названия полей отличаются)
    if (type === 'order' || type === 'ordercontent' || type === 'ordercard') {
      if (value === 'id') {
        return 'Номер заявки';
      }
    }

    if (type === 'ordercontent') {
      if (value === 'createdAt') {
        return 'Дата заявки';
      }
    }

    switch (value) {
      case 'id':
        return 'ID';
      case 'akspromPartnerId':
        return 'Код Акспром';
      case 'akspromOrderId':
        return 'Номер счёта';
      case 'akspromUserId':
        return 'Акспром ID пользователя';
      case 'invoiceDate':
        return 'Дата счёта';
      case 'orderInformation':
        return 'Информация о заказе';
      case 'complectationStatus':
        return 'Статус комплектования';
      case 'paymentStatus':
        return 'Статус оплаты';
      case 'status':
        return 'Статус';
      case 'shipmentDate':
        return 'Дата отгрузки';
      case 'cost':
        return 'Сумма';
      case 'confirmedDate':
        return 'Дата подтверждения';
      case 'documents':
        return 'Документы';
      case 'productName':
        return 'Наименование';
      case 'ownerId':
        return 'Владелец';
      case 'quantity':
        return 'Количество';
      case 'articleNumber':
        return 'Артикул';
      case 'pricePerItem':
        return 'Цена';
      case 'description':
        return 'Описание';
      case 'akspromProductId':
        return 'Код';
      case 'vendorCode':
        return 'Артикул';
      case 'imagePath':
        return 'Изображение';
      case 'createdAt':
        return 'Дата создания';
      case 'updatedAt':
        return 'Дата изменения';
      case 'email':
        return 'E-mail';
      case 'password':
        return 'Пароль';
      case 'firstname':
        return 'Имя';
      case 'surname':
        return 'Фамилия';
      case 'role':
        return 'Роль';
      case 'name':
        return 'Наименование';
      case 'inn':
        return 'ИНН';
      case 'active':
        return 'Активность';
      case 'partnerName':
        return 'Контрагент';
      case 'userName':
        return 'Клиент';
      case 'manager':
        return 'Менеджер';
      default:
        return value;
    }
  };

  const rolesTranslate = (value: IRole): string => {
    switch (value) {
      case 'admin':
        return 'Администратор';
      case 'manager':
        return 'Менеджер';
      case 'customer':
        return 'Покупатель';
      default:
        return value;
    }
  };

  const dataTypeTranslate = (value: DataType): string => {
    switch (value) {
      case 'order':
        return 'Заказы';
      case 'ordercontent':
        return 'Товары в заказах';
      case 'ordercard':
        return 'Карточка заказа';
      case 'product':
        return 'Товары';
      case 'partner':
        return 'Контрагенты';
      case 'user':
        return 'Пользователи';
      default:
        return value;
    }
  };

  const pageTranslate = (value: IPage): string => {
    switch (value) {
      case '/orders':
        return 'Заказы';
      case '/ordercontents':
        return 'Товары в заказах';
      case '/partners':
        return 'Контрагенты';
      case '/users':
        return 'Пользователи';
      case '/fields':
        return 'Поля';
      case '/roles':
        return 'Роли';
      case '/mail':
        return 'Почта';
      case '/logger':
        return 'Логгер';
      default:
        return value;
    }
  };

  return { colNamesTranslate, rolesTranslate, dataTypeTranslate, pageTranslate };
}
