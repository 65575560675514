import styled from 'styled-components';

import { theme } from '../../../styles/theme';

export const Header = styled.div`
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.background.main};
  color: ${theme.colors.text.header};
  height: 64px;
  box-shadow: 0 0.1rem 2rem ${theme.colors.background.main};
  top: 0;
  z-index: 15;
  padding: 10px 20px;
`;
