import styled from 'styled-components';

interface SVGIconProps {
  $size?: string;
  $ext: string;
}

const getIconByFileExt = (ext: string) => {
  if (ext === 'xlsx' || ext === 'xls') {
    return 'url("icons/downloadExcel.svg") ';
  }
  if (ext === 'pdf') {
    return 'url("icons/downloadPdf.svg") ';
  }
  if (ext === 'doc' || ext === 'docx') {
    return 'url("icons/downloadWord.svg") ';
  }
  if (ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
    return 'url("icons/downloadImage.svg") ';
  }
  if (ext === 'zip' || ext === 'rar') {
    return 'url("icons/downloadArchive.svg") ';
  }
  return 'url("icons/downloadUnknown.svg") ';
};

const SVGIcon = styled.div<SVGIconProps>`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: ${({ $ext }) => getIconByFileExt($ext)};
  width: ${({ $size }) => $size || '20px'};
  height: ${({ $size }) => $size || '20px'};
`;

export default SVGIcon;
