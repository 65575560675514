import styled from 'styled-components';

interface TitleProps {
  $fontSize?: string;
  $fontWeight?: string;
  $margin?: string;
  $padding?: string;
  $color?: string;
  $textAlign?: 'left' | 'center' | 'right';
}

const Title = styled.div<TitleProps>`
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  margin: ${({ $margin }) => $margin};
  padding: ${({ $padding }) => $padding};
  color: ${({ $color }) => $color};
  text-align: ${({ $textAlign }) => $textAlign};
`;

export default Title;
