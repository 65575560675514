import styled from 'styled-components';

import { theme } from '../../../styles/theme';

interface IStyleProps {
  user?: 'host' | 'current';
}

export const MessageWrapper = styled.div<IStyleProps>`
  display: flex;
  justify-content: ${(props) =>
    props.user === 'current' ? 'flex-end' : props.user === 'host' ? 'center' : 'flex-start'};
  margin-top: 3px;
`;

export const Date = styled.div<IStyleProps>`
  display: flex;
  align-items: center;
  color: ${theme.colors.chat.text.userName};
  letter-spacing: 0.5px;
  padding: ${(props) => (props.user === 'current' ? '0 10px 0 0' : '0 0 0 10px')};
  font-size: 0.7rem;
`;

export const MessageBox = styled.div<IStyleProps>`
  border-radius: 10px;
  padding: 5px 20px;
  display: inline-block;
  max-width: 70%;
  background: ${(props) =>
    props.user === 'current'
      ? theme.colors.chat.background.currentUser
      : props.user === 'host'
      ? theme.colors.chat.background.hostUser
      : theme.colors.chat.background.anotherUser};
`;

export const MessageText = styled.div<IStyleProps>`
  color: ${(props) =>
    props.user === 'current'
      ? theme.colors.chat.text.currentUser
      : theme.colors.chat.text.anotherUser};
`;
