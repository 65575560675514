import { useEffect } from 'react';
import { notification } from 'antd';

import { useAppSelector, useActions } from '../../../hooks/redux.hook';

import { useChat } from '../../../hooks/chat.hook';

type Notifications = 'success' | 'error' | 'info' | 'warning';

// Компонент Toast является по сути хуком antd. Он ничего не возвращает, просто запускает функцию, в которой прописаны необходимые параметры
export default function Toast() {
  const { showChatDrawer, setChosenRoom } = useActions();
  const {
    // chatDrawerVisible,
    message: { text, type, topic, data },
  } = useAppSelector((state) => state.app);
  const { roomsData } = useAppSelector((state) => state.chat);
  const { setSocketToRoom } = useChat();

  useEffect(() => {
    // Сообщение не показывается, если оно касается чата и окно чата открыто
    // Так было раньше. Новую версию с чатом не проверял
    // if (topic === 'chat' && chatDrawerVisible) {
    //   return;
    // }
    // При любом открытом дроере, если это не сообщение из чата, toast не отображается
    if (topic !== 'chat' && document.querySelector('.ant-drawer-open')) {
      return;
    }

    openNotificationWithIcon(
      type === 'success'
        ? 'success'
        : type === 'danger'
        ? 'error'
        : type === 'warning'
        ? 'warning'
        : 'info'
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // При клике на уведомление о новом сообщении: открывается чат, пользователь подключается к комнате (передается в data), если еще не подключен (для этого проверка !roomsData[data]), в чате выбирается комната (чтоб при открытии окна сразу же оказаться в ней)
  const chatMessageClickHandler = (): void => {
    showChatDrawer();
    if (!roomsData[data]) {
      setSocketToRoom(data);
    }
    setChosenRoom(data);
  };

  const openNotificationWithIcon = (type: Notifications): void => {
    notification[type]({
      message: '',
      // ключ задан постоянным для того, чтобы сообщения не выскакивали одно под другим. Иначе при каждом вызове useSelector будет вызваться функция. Может возникнуть баг с несколькими одинаковыми сообщениями
      key: 'updatable',
      placement: 'bottomRight',
      description: text,
      // duration: 10,
      style: { paddingRight: '40px', cursor: topic === 'chat' ? 'pointer' : undefined },
      onClick: (): void => {
        if (topic === 'chat') {
          console.log('ok');
          chatMessageClickHandler();
          notification.destroy();
        }
      },
    });
  };

  return null;
}
