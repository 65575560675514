import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import * as C from '../../ui/Styled';
import * as S from './styles';
import { theme } from '../../../styles/theme';

import { useChat } from '../../../hooks/chat.hook';
import { useAppSelector, useActions } from '../../../hooks/redux.hook';

export default function ChatList() {
  const isDefaultScreen = useMediaQuery(theme.media.default);
  const { setChosenRoom, clearRoomUnviewedMessages } = useActions();
  const { allRooms, roomsData, chosenRoom, newMessages } = useAppSelector((state) => state.chat);
  const { akspromUserId } = useAppSelector((state) => state.user.userData);
  const { setSocketToRoom } = useChat();

  // Обнуление количества новых сообщений для выбранной комнаты
  useEffect(() => {
    chosenRoom && clearRoomUnviewedMessages(chosenRoom);
  }, [chosenRoom]); // eslint-disable-line react-hooks/exhaustive-deps

  // Обработчик нажатия на кнопку выбора чата. Если в сторе нет комнаты, соответствующей нажатой кнопке, то создается новый socket, записывается в стор (roomsData) с привязкой к данной комнате, и производится присоединение (join) к данной комнате. Далее в любом случае в chosenRoom записывается выбранная комната (нажатая кнопка). Для каждой комнаты создается свой socket, т.к. иначе невозможно отследить куда отправляется сообщение
  const chooseRoomHandler = (room: string) => {
    if (!roomsData[room]) {
      setSocketToRoom(room);
    }
    setChosenRoom(room);
  };

  return (
    <S.ChatListWrapper $isDefaultScreen={isDefaultScreen}>
      {/* Из списка всех открытых комнат извлекаем данные из создаем для каждой комнаты кнопку */}
      {allRooms.map((room) => {
        // Из названия комнаты извлекаем номер пользователя и заказа
        const userId = room.split('-')[0];
        const akspromOrderId = room.substr(userId.length + 1, room.length);

        // Кнопка чата с самим собой не отображается
        if (userId === akspromUserId) {
          return null;
        }

        return (
          <S.ChatListButton
            type={room === chosenRoom ? 'primary' : undefined}
            key={room}
            // При клике на кнопку помещаем название соответствующей комнаты в стор для отображения нужного чата
            onClick={() => chooseRoomHandler(room)}
          >
            <C.Flex $justify="space-between" $align="center">
              {/* Название чата (пользователь-заказ) */}
              <div>
                {akspromOrderId === 'general'
                  ? `${akspromUserId} общие вопросы`
                  : `${akspromUserId} ${akspromOrderId}`}
              </div>

              {/* Количество новых сообщений */}
              {room !== chosenRoom ? (
                newMessages[room] ? (
                  <S.NewMessage>{newMessages[room]}</S.NewMessage>
                ) : null
              ) : null}
            </C.Flex>
          </S.ChatListButton>
        );
      })}
    </S.ChatListWrapper>
  );
}
