import { lazy } from 'react';

export type IPage =
  | '/orders'
  | '/ordercontents'
  | '/products'
  | '/partners'
  | '/users'
  | '/mail'
  | '/fields'
  | '/roles'
  | '/logger';

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export enum RouteNames {
  LOGIN = '/',
  PASSFORGOT = '/passforgot',
  PASSRESET = '/passreset/:token',
  ORDERS = '/orders',
  ORDER_CONTENTS = '/ordercontents',
  PRODUCTS = '/products',
  PARTNERS = '/partners',
  USERS = '/users',
  MAIL = '/mail',
  FIELDS = '/fields',
  ROLES = '/roles',
  LOGGER = '/logger',
  NOTFOUND = '*',
}

const LoginPage = lazy(() => import('../pages/LoginPage'));
const OrdersPage = lazy(() => import('../pages/OrdersPage'));
const OrderContentsPage = lazy(() => import('../pages/OrderContentsPage'));
const UsersPage = lazy(() => import('../pages/UsersPage'));
const PartnersPage = lazy(() => import('../pages/PartnersPage'));
const RolesPage = lazy(() => import('../pages/RolesPage'));
const FieldsPage = lazy(() => import('../pages/FieldsPage'));
const MailPage = lazy(() => import('../pages/MailPage'));
const LoggerPage = lazy(() => import('../pages/LoggerPage'));
const NotFoundPage = lazy(() => import('../pages/404Page'));

export const publicRoutes: IRoute[] = [
  { path: RouteNames.LOGIN, element: <LoginPage /> },
  { path: RouteNames.PASSFORGOT, element: <LoginPage pageType="passForgot" /> },
  { path: RouteNames.PASSRESET, element: <LoginPage pageType="passReset" /> },
  { path: RouteNames.NOTFOUND, element: <NotFoundPage /> },
];

export const anyUserPrivateRoutes: IRoute[] = [
  { path: RouteNames.ORDERS, element: <OrdersPage /> },
  { path: RouteNames.ORDER_CONTENTS, element: <OrderContentsPage /> },
];

export const adminPrivateRoutes: IRoute[] = [
  { path: RouteNames.USERS, element: <UsersPage /> },
  { path: RouteNames.PARTNERS, element: <PartnersPage /> },
  { path: RouteNames.ROLES, element: <RolesPage /> },
  { path: RouteNames.FIELDS, element: <FieldsPage /> },
  { path: RouteNames.MAIL, element: <MailPage /> },
  { path: RouteNames.LOGGER, element: <LoggerPage /> },
];
