import io from 'socket.io-client';

import { useAppSelector, useActions } from './redux.hook';

export const useChat = () => {
  const { setAllRooms, showMessage, addUnviewedMessage, setManagerSocket, setRoomsData } =
    useActions();
  const { akspromUserId, firstname, role } = useAppSelector((state) => state.user.userData);

  // Присоединение менеджера к чату при старте приложения. На сервере происходит присоединение к комнате 'manager'. Нужно для того, чтобы иметь возможность получать уведомления о новых сообщениях сразу при входе в приложение. Полученный socket помещается в стор chat.managerSocket. Одновременно с этим производится подписка на изменение списка открытых комнат пользователей
  const setManagerToChat = async (): Promise<void> => {
    const socket = io('/');

    socket.on('connect', () => {});

    socket.emit('joinManager', { room: 'manager' }, (error: any) => {
      if (error) {
        console.log(error);
      }
    });

    // Все открытые комнаты всех пользователей. При открытии пользователем нового чата, добавляется новая комната в формате akspromUserId-akspromOrderId. Список всех открытых комнат приходит с сервера и отправляется в стор для обновления кнопок в чате у менеджера
    socket.on('getAllRooms', (roomArray: string[]): void => {
      setAllRooms(roomArray);
    });

    // При получении нового сообщения от любого покупателя в любую комнату вызываем уведомление (если окно чата закрыто)
    socket.on('newMessage', (room: string): void => {
      // Из названия комнаты извлекаем номер пользователя и заказа (для отображения в служебном сообщении для менеджера)
      const userId: string = room.split('-')[0];
      const orderId: string = room.substr(userId.length + 1, room.length);

      // Отображение сообщения о новом сообщении (при закрытом окне чата)

      showMessage({
        type: 'warning',
        text:
          orderId === 'general'
            ? `Пользователь ${userId} отправил сообщение по общим вопросам`
            : `Пользователь ${userId} отправил сообщение по заказу ${orderId}`,
        topic: 'chat',
        data: room,
      });

      // Увеличиение количества непрочитанных сообщений в данной комнате для отображения в ChatList у менеджера
      addUnviewedMessage(room);
    });
    setManagerSocket(socket);
  };

  // Получение сокета, происоединение к комнате, полученной в аргументе, и запись полученного сокета, а также параметра в стор с привязкой к комнате
  const setSocketToRoom = (room: string): typeof socket => {
    const socket = io('/');
    socket.emit('join', { akspromUserId, firstname, role, room }, (error: any) => {
      if (error) {
        console.log(error);
      }
    });
    setRoomsData({ [room]: { socket } });
    return socket;
  };

  return { setManagerToChat, setSocketToRoom };
};
