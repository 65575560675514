import { useState } from 'react';
import { Button } from 'antd';

import * as C from '../../ui/Styled';
import * as S from './styles';

interface IInputProps {
  message: string;
  setMessage: (message: string) => void;
  sendMessage: (e: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent) => void;
  typing: (state: boolean) => void;
}

export default function Input({ message, setMessage, sendMessage, typing }: IInputProps) {
  const [typingStopTimer, setTypingStopTimer] = useState<NodeJS.Timeout>(0);

  return (
    <C.Flex $justify="space-between" $align="center" $height="30px" $margin="5px 0 0">
      <S.InputArea
        type="text"
        placeholder="Введите сообщение..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.ctrlKey && e.key === 'Enter') {
            clearTimeout(typingStopTimer);
            typing(false);
            sendMessage(e);
          } else {
            typing(true);
            clearTimeout(typingStopTimer);
            setTypingStopTimer(setTimeout(() => typing(false), 2000));
          }
        }}
      />
      <Button type="primary" onClick={(e: React.MouseEvent) => sendMessage(e)}>
        Отправить
      </Button>
    </C.Flex>
  );
}
