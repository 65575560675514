import styled from 'styled-components';

interface AntIconProps {
  $fontSize?: string;
  $colorMain?: string;
  $colorHover?: string;
  $cursor?: string;
}

const AntIcon = styled.div<AntIconProps>`
  font-size: ${({ $fontSize }) => $fontSize || '25px'};
  color: ${({ $colorMain, theme }) => $colorMain || theme.colors.logo.main};
  transition: 0.3s color;
  &:hover {
    color: ${({ $colorHover, theme }) => $colorHover || theme.colors.logo.hover};
    cursor: ${({ $cursor }) => $cursor || 'pointer'};
  }
`;

export default AntIcon;
