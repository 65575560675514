import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  media: {
    default: { minWidth: 768 },
  },
  colors: {
    background: { main: '#001529' },
    line: { main: '#1890ff' },
    loader: '#00acac',
    text: {
      sider: 'rgba(255, 255, 255, 0.65)',
      header: 'rgba(255, 255, 255, 0.9)',
      main: 'rgba(255, 255, 255, 0.65)',
    },
    chat: {
      background: { currentUser: '#1890ff', anotherUser: '#D3D3D3', hostUser: '#B0E0E6' },
      text: { currentUser: '#fff', anotherUser: '#353535', userName: '#828282' },
    },
    message: {
      background: { success: '#d1e7dd', danger: '#f8d7da' },
      border: { success: '#badbcc', danger: '#f5c2c7' },
      text: { success: '#0f5132', danger: '#842029' },
    },
    logo: {
      main: '#1890ff',
      hover: '#40a9ff',
    },
    activeness: {
      active: '#52c41a',
      inactive: 'red',
    },
  },
};

export { theme };
