import { useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useMediaQuery } from 'react-responsive';
import { Button, Form, Input, Typography } from 'antd';

import { useAddFastOrderMutation } from '../../../services/rtkQuery/orderAPI';
import { useSendFastOrderMailMutation } from '../../../services/rtkQuery/mailAPI';

import DrawerWithSwipe from '../DrawerWithSwipe';
import Dropzone from '../../ui/Dropzone';
import Loader from '../../ui/Loader';
import Message from '../../ui/Message';
import { theme } from '../../../styles/theme';

import { useAppSelector, useActions } from '../../../hooks/redux.hook';

interface IFastOrderDrawerProps {
  setDrawerVisible: (visible: boolean) => void;
  drawerVisible: boolean;
}
interface IUserDataForUpload {
  fio: string;
  email: string;
  content: string;
  orderInformation: string;
  akspromUserId: string;
}

// Дроер быстрого заказа (активируется в хедере покупателя)
export default function FastOrderDrawer({
  setDrawerVisible,
  drawerVisible,
}: IFastOrderDrawerProps) {
  const { showMessage } = useActions();
  const { message } = useAppSelector((state) => state.app);
  const userData = useAppSelector((state) => state.user.userData);
  const { Paragraph } = Typography;
  const isDefaultScreen = useMediaQuery(theme.media.default);
  const [addFastOrderQuery, { isLoading }] = useAddFastOrderMutation();
  const [sendFastOrderMailQuery] = useSendFastOrderMailMutation();

  // Ссылка на форму быстрого заказа (для взаимодействия с ней с помощью хука из Ant Design)
  const [fastOrderForm] = Form.useForm();

  // Файлы в дропзоне
  const [files, setFiles] = useState<FileWithPath[]>([]);

  // Кнопка "Отправить"
  const sendFastOrderDataHandler = async () => {
    // Получение всех данных из формы (кроме файлов)
    const fastOrderData = await fastOrderForm.getFieldsValue();

    // Запрет на отправку пустого заказа
    if (!files.length && !fastOrderData.orderTextArea) {
      return showMessage({ text: 'Заполните данные о заказе', type: 'danger' });
    }

    const newFastOrderData = new FormData();

    // Составление объекта с данными
    const userDataForUpload: IUserDataForUpload = {
      fio: `${userData.firstname} ${userData.surname}`,
      email: userData.email,
      content: fastOrderData.orderTextArea || '',
      orderInformation: fastOrderData.orderTextArea || '',
      akspromUserId: userData.akspromUserId,
    };

    // Помещение объекта в FormData
    for (let key in userDataForUpload) {
      newFastOrderData.append(key, userDataForUpload[key as keyof IUserDataForUpload]);
    }

    // Помещение файлов в FormData
    files.forEach((file) => newFastOrderData.append('doc', file));

    // Отправка письма с новым заказом
    await sendFastOrderMailQuery(newFastOrderData);

    // Добавление заказа в БД
    await addFastOrderQuery(newFastOrderData);

    // Очистка формы, стейта с файлами
    fastOrderForm.resetFields();

    setFiles([]);
    setDrawerVisible(false);
  };

  return (
    <DrawerWithSwipe
      title={<div style={{ fontWeight: 600 }}>Быстрый заказ</div>}
      width={isDefaultScreen ? 500 : ''}
      // Крестик закрытия скрыт
      closable={false}
      onClose={() => {
        setDrawerVisible(false);
        // Очистка формы
        fastOrderForm.resetFields();
      }}
      visible={drawerVisible}
      setDrawerVisible={setDrawerVisible}
      footer={
        <>
          <Button
            style={{ marginRight: 8 }}
            type="primary"
            htmlType="button"
            onClick={sendFastOrderDataHandler}
          >
            Отправить
          </Button>
          <Button
            style={{ marginRight: 8 }}
            onClick={() => {
              // Задвигание дроера, очистка формы
              setDrawerVisible(false);
              fastOrderForm.resetFields();
            }}
          >
            Отменить
          </Button>
        </>
      }
    >
      <Paragraph>
        Данный функционал предназначен для заказа любого количества позиций в пару кликов.
      </Paragraph>
      <Paragraph>
        {isDefaultScreen
          ? 'Заказ можно сделать, заполнив форму в произвольном виде, или, загрузив файлы с перечнем необходимых вам позиций.'
          : 'Заказ можно сделать, заполнив форму в произвольном виде, или, загрузив файлы с перечнем необходимых вам позиций, либо сделав фото документа или товара'}
      </Paragraph>
      <Paragraph>
        Наш менеджер обработает заказ и выставит счет или свяжется с вами для уточнения деталей.
      </Paragraph>
      <Form
        form={fastOrderForm}
        layout="vertical"
        name="fastOrderForm"
        hideRequiredMark
        style={{ marginTop: '20px' }}
      >
        <Form.Item name="orderTextArea">
          <Input.TextArea allowClear={true} autoSize={{ minRows: 5 }} />
        </Form.Item>

        <Form.Item name="uploadedFiles">
          <Dropzone files={files} setFiles={setFiles} single={false}></Dropzone>
        </Form.Item>

        {isLoading ? <Loader /> : null}
        {message.text ? <Message /> : null}
      </Form>
    </DrawerWithSwipe>
  );
}
