import styled from 'styled-components';
import { Button } from 'antd';

import { theme } from '../../../styles/theme';

interface ChatListWrapperProps {
  $isDefaultScreen: boolean;
}

export const ChatListWrapper = styled.div<ChatListWrapperProps>`
  width: ${({ $isDefaultScreen }) => ($isDefaultScreen ? '30%' : '100%')};
  height: 100%;
  padding-right: 5px;
  overflow-y: auto;
  border-right: ${({ $isDefaultScreen }) => ($isDefaultScreen ? '1px solid gray' : 'none')};
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f9f9fd;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #001529;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #f9f9fd;
  }
`;

export const ChatListButton = styled(Button)`
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  overflow: hidden;
`;

export const NewMessage = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${theme.colors.chat.background.currentUser};
  color: #fff;
`;
