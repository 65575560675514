import * as S from './styled';
import MainMenu from '../MainMenu';

interface MobileMenuProps {
  mobileMenuCollapsed: boolean;
  mobileMenuCloseHandler: () => void;
}

export default function MobileMenu({
  mobileMenuCollapsed,
  mobileMenuCloseHandler,
}: MobileMenuProps) {
  return (
    <S.MobileMenuDrawerWrapper
      mobileMenuCollapsed={mobileMenuCollapsed}
      onClick={(e) => {
        // Предотвращение закрытия окна при клике на пункт меню
        if (e.target === document.getElementById('mask')) {
          mobileMenuCloseHandler();
        }
      }}
    >
      <S.MobileMenuMask id="mask" mobileMenuCollapsed={mobileMenuCollapsed} />
      <S.MobileMenu mobileMenuCollapsed={mobileMenuCollapsed}>
        <MainMenu mode="inline" onSelect={mobileMenuCloseHandler} />
      </S.MobileMenu>
    </S.MobileMenuDrawerWrapper>
  );
}
