import styled, { css } from 'styled-components';

interface FlexProps {
  $direction?: 'row' | 'column';
  $justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  $align?: 'stretch' | 'flex-start' | 'flex-end' | 'center';
  $wrap?: 'nowrap' | 'wrap';
  $margin?: string;
  $padding?: string;
  $center?: boolean;
  $width?: string;
  $height?: string;
}

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: ${({ $justify }) => $justify};
  align-items: ${({ $align }) => $align};
  flex-wrap: ${({ $wrap }) => $wrap};
  margin: ${({ $margin }) => $margin};
  padding: ${({ $padding }) => $padding};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  ${({ $center }) =>
    $center &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

export default Flex;
