import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../models/IUser';

import { IColumns } from '../../types/data';
import { ISortingColumns } from '../../types/user';

interface UserState {
  userData: IUser;
  isAuth: boolean;
}

const initialState: UserState = {
  userData: {} as IUser,
  isAuth: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userSetDataAndAuth: (state, action: PayloadAction<IUser>) => {
      state.userData = action.payload;
      state.isAuth = true;
    },
    userSetInitialState: (state) => {
      state.userData = {} as IUser;
      state.isAuth = false;
    },
    userSetChosenColumns: (state, action: PayloadAction<IColumns>) => {
      state.userData.settings.userchosencolumns = action.payload;
    },
    userSetShowTheTime: (state, action: PayloadAction<boolean>) => {
      state.userData.settings.showthetime = action.payload;
    },
    userSetSortingColumn: (state, action: PayloadAction<ISortingColumns>) => {
      state.userData.settings.usersortedcolumns = action.payload;
    },
  },
});

export const { actions, reducer } = userSlice;
