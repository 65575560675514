import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { useAppSelector } from '../../hooks/redux.hook';

import * as S from './styled';
import FastOrderDrawer from '../Drawers/FastOrderDrawer';
import MobileMenu from './MobileMenu';
import Toast from '../ui/Toast';
import Sider from './Sider';
import Header from './Header';
import ChatDrawer from '../Chat/ChatDrawer';
import { theme } from '../../styles/theme';
import SuspenseWrapper from '../ui/SuspenseWrapper';

export default function MainLayout() {
  const isDefaultScreen = useMediaQuery(theme.media.default);
  const { message } = useAppSelector((state) => state.app);

  // Активность дроера быстрого заказа
  const [fastOrderDrawerVisible, setFastOrderDrawerVisible] = useState(false);
  // Состояние сайдбара (свернут/развернут)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  // Состояние мобильного меню (свернуто/развернуто)
  const [mobileMenuCollapsed, setMobileMenuCollapsed] = useState(true);

  // При закрытии мобильного меню (нажатие на крест) отменяется запрет на скролл
  const mobileMenuCloseHandler = () => {
    document.body.classList.remove('scroll-cancel');
    setMobileMenuCollapsed(true);
  };

  return (
    // без minHeight высота меню равна высоте контента (некрасиво)
    <Layout style={{ minHeight: '100vh' }}>
      {/* Десктопный сайдбар*/}
      {isDefaultScreen ? (
        <Sider sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      ) : null}

      {/* Xедер и основной контент страницы */}
      <Layout
        // При фиксировании сайдбара, весь контент начинается от левого края (а не от сайдбара). Поэтому, в соответствии с документацией, прописываем инлайн стили. Ставим отступ в зависимости от того свернут он или развернут. Transition задан, чтоб двигать синхронно с самим сайдбаром.
        style={
          isDefaultScreen
            ? sidebarCollapsed
              ? { marginLeft: 80, transition: '0.2s ease' }
              : { marginLeft: 200, transition: '0.2s ease' }
            : undefined
        }
      >
        <Header
          mobileMenuCollapsed={mobileMenuCollapsed}
          setMobileMenuCollapsed={setMobileMenuCollapsed}
          mobileMenuCloseHandler={mobileMenuCloseHandler}
          setFastOrderDrawerVisible={setFastOrderDrawerVisible}
        />

        {/* Основной контент */}
        <Layout.Content>
          {/* Мобильное меню*/}
          {!isDefaultScreen && (
            <MobileMenu
              mobileMenuCollapsed={mobileMenuCollapsed}
              mobileMenuCloseHandler={mobileMenuCloseHandler}
            />
          )}
          {/* Вывод страниц */}
          <SuspenseWrapper fallback="logo">
            <S.PagesLayout $isDefaultScreen={isDefaultScreen}>
              <Outlet /> {/* children из библиотеки ReactRouter */}
            </S.PagesLayout>
          </SuspenseWrapper>
        </Layout.Content>

        <Layout.Footer
          style={{
            textAlign: 'center',
            backgroundColor: '#fff',
            padding: isDefaultScreen ? '15px 30px' : '10px',
          }}
        >
          ©Акспром
        </Layout.Footer>
      </Layout>

      {/* Дроер быстрого заказа */}
      <FastOrderDrawer
        drawerVisible={fastOrderDrawerVisible}
        setDrawerVisible={setFastOrderDrawerVisible}
      />

      {/* Сообщения */}
      {message.text && !fastOrderDrawerVisible ? <Toast /> : null}

      {/* Чат */}
      <ChatDrawer />
    </Layout>
  );
}
