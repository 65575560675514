import { useAppSelector } from '../../../hooks/redux.hook';

import * as S from './styles';

interface IMessageProps {
  style?: { [property: string]: string | number };
}

export default function Message({ style }: IMessageProps) {
  const { text, type } = useAppSelector((state) => state.app.message);

  return (
    <S.MessageField style={style} type={type}>
      {text}
    </S.MessageField>
  );
}
