import { bindActionCreators } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { RootState, AppDispatch, ActionCreators } from '../redux/store';
import AsyncActionCreators from '../redux/actionCreators';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  return bindActionCreators({ ...ActionCreators, ...AsyncActionCreators }, dispatch);
};
