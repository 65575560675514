import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { useChat } from './hooks/chat.hook';

import { useAppSelector, useActions } from './hooks/redux.hook';
import { useLazyUserRefreshTokenQuery } from './services/rtkQuery/authAPI';
import getFingerprint from './helpers/getFingerprint';
import { AppRouter } from './components/AppRouter';

const App = () => {
  const { userLogin } = useActions();
  const [userRefreshTokenQuery] = useLazyUserRefreshTokenQuery();
  const {
    isAuth,
    userData: { role },
  } = useAppSelector((state) => state.user);

  const { setManagerToChat } = useChat();

  // Инициализация приложения при первом запуске (перезагрузке)
  const appInitialize = async () => {
    if (localStorage.getItem('token')) {
      const fingerprint = await getFingerprint();

      const newUserData = await userRefreshTokenQuery(fingerprint, true);
      if (newUserData.data) {
        userLogin(newUserData.data);
      }
    }
  };

  // Начальная загрузка приложения. При наличии AccessToken в LocalStorage токены обновляются, начальные даные загружаются в стор.
  useEffect(() => {
    appInitialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Для Не Покупателей сразу при старте приложения назначается socket, который заносится в стор (chat.managerSocket). Сокет необходим для возможности показа уведомлений о новых сообщениях от покупателей
  useEffect(() => {
    if (isAuth && role !== 'customer') {
      setManagerToChat();
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <AppRouter />
    </Router>
  );
};

export default App;
