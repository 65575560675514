import styled from 'styled-components';

interface ILogoProps {
  collapsed: boolean;
}

export const Logo = styled.div<ILogoProps>`
  height: 80px;
  margin: 20px 0;
  background: ${({ collapsed }) =>
    collapsed
      ? "url('/images/logo/logo_main_without_text.png') center center/60% no-repeat"
      : "url('/images/logo/logo_main.png') center center/contain no-repeat"};
`;
