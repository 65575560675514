import { baseQuerySliceWithRefreshToken } from './baseQuerySlices';

const mailAPI = baseQuerySliceWithRefreshToken.injectEndpoints({
  endpoints: (builder) => ({
    sendFastOrderMail: builder.mutation<{ message: string }, FormData>({
      query: (newFastOrderData) => ({
        url: 'mail/neworder',
        method: 'POST',
        body: newFastOrderData,
      }),
    }),
    // Типизировать реквест
    sendTestMail: builder.mutation<{ message: string }, any>({
      query: (newMailSettings) => ({
        url: 'mail/test',
        method: 'POST',
        body: newMailSettings,
      }),
    }),
  }),
});

export const { useSendFastOrderMailMutation, useSendTestMailMutation } = mailAPI;
