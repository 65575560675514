import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { showMessageWithHiding } from './helper';

interface IResult {
  data?: any;
  error?: any;
}

export const messagesAndErrorsHandling = (result: IResult, args: any, api: BaseQueryApi) => {
  // console.log(result);
  // console.log(args);

  // Вывод сообщений об успехе/ошибке
  if (result.data?.message && !args.url.includes('logout')) {
    showMessageWithHiding(api, { text: result.data.message, type: 'success' });
  }

  // Вывод сообщений об ошибках (исключения: обновление токена (неверный токен, срок действия и т.п.))
  if (result.error && result.error.status !== 401) {
    // При наличии ошибок валидации - вывод подробностей
    if (result.error.data.validationErrors) {
      showMessageWithHiding(api, {
        text: result.error.data.validationErrors[0].msg,
        type: 'danger',
      });
    } else {
      showMessageWithHiding(api, { text: result.error.data.message, type: 'danger' });
    }
  }
};
