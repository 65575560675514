import styled from 'styled-components';

interface IStyledProps {
  mobileMenuCollapsed: boolean;
}

export const MobileMenuDrawerWrapper = styled.div<IStyledProps>`
  position: fixed;
  z-index: ${({ mobileMenuCollapsed }) => (mobileMenuCollapsed ? -1 : 10)};
  height: 100%;
  width: 100%;
`;

export const MobileMenuMask = styled.div<IStyledProps>`
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  opacity: ${({ mobileMenuCollapsed }) => (mobileMenuCollapsed ? 0 : 1)};
  background-color: rgba(0, 0, 0, 0.45);
  transition: 0.2s all;
`;

export const MobileMenu = styled.div<IStyledProps>`
  position: absolute;
  z-index: 10;
  top: ${({ mobileMenuCollapsed }) => (mobileMenuCollapsed ? 'calc(-100%)' : 0)};
  width: 100%;
  transition: 0.2s all;
`;
