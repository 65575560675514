import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';

import { IMessages, IMessage, RoomsData } from '../../types/chat';

interface ChatState {
  // socket менеджера. Назначается при запуске приложения. Необходим для входа менеджера в чат сразу при старте приложения для возможности отслеживания создания новых комнат и сообщений без открытия окна чата
  managerSocket: Socket;
  // Массив всех открытых комнат. Приходит с сервера
  allRooms: [] | string[];
  // Данные о комнатах в формате {room:{socket: ''}}. Необходимы для определения необходимости создания нового сокета для комнаты, если его еще нет, либо переиспользования уже имеющегося
  roomsData: RoomsData;
  // Комната, выбранная менеджером по клику на кнопку
  chosenRoom: null | string;
  // Все принятые сообщения. При инициализации чата из БД извлекаются сообщения для данного пользователя и данной комнаты, помещаются в allMessages в объект [roomName]:{}. В объекте сообщения делятся на три типа: прочитанные (viewed:[]), непрочитанные (notViewed: []) - это те, которые приходят из БД. В окне сообщений непрочитанные располагаются снизу и выделяются особым способом. Все новые сообщения, пришедшие к пользователю с открытым чатом помещаются в новый объект (afterNotViewed:[]). Они в окне сообщений располагаются под непрочитанными.
  allMessages: IMessages;
  // Подсчет количества непрочитанных сообщений для менеджера. При каждом пришедшем сообщении с socket.on('newMessage') в стейт записывается +1 сообщение для соответствующей комнаты. Далее общее количество новых сообщений отображается в кнопках ChatList (кроме кнопки текущей комнаты)
  newMessages: { [room: string]: number };
}

const initialState: ChatState = {
  managerSocket: {} as Socket,
  allRooms: [],
  roomsData: {} as RoomsData,
  chosenRoom: '',
  allMessages: {},
  newMessages: {},
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // ! Не получается вставить тип Socket
    setManagerSocket: (state, action: PayloadAction<any>) => {
      state.managerSocket = action.payload;
    },
    setAllRooms: (state, action: PayloadAction<string[]>) => {
      state.allRooms = action.payload;
    },
    // ! Не получается вставить тип Socket
    setRoomsData: (state, action: PayloadAction<any>) => {
      state.roomsData = { ...state.roomsData, ...action.payload };
    },
    setChosenRoom: (state, action: PayloadAction<string>) => {
      state.chosenRoom = action.payload;
    },
    setInitialMessages: (
      state,
      action: PayloadAction<{
        room: string;
        viewedMessages: IMessage[];
        notViewedMessages: IMessage[];
      }>
    ) => {
      state.allMessages[action.payload.room] = {
        viewed: action.payload.viewedMessages,
        notViewed: action.payload.notViewedMessages,
      };
    },
    addChatMessage: (state, action: PayloadAction<{ room: string; message: IMessage }>) => {
      // Если комнаты в сообщениях еще не было, то это начало беседы, записываем все новые сообщения в массив viewed
      if (!state.allMessages[action.payload.room]) {
        state.allMessages[action.payload.room] = {
          viewed: [],
        };
      }
      // Если в списке сообщений есть непрочитанные, то следующие добавляеются после них
      if (state.allMessages[action.payload.room].notViewed?.length) {
        if (!state.allMessages[action.payload.room].afterNotViewed) {
          state.allMessages[action.payload.room].afterNotViewed = [];
        }
        state.allMessages[action.payload.room] = {
          ...state.allMessages[action.payload.room],
          afterNotViewed: [
            ...state.allMessages[action.payload.room].afterNotViewed!,
            action.payload.message,
          ],
        };
        return;
      }
      state.allMessages[action.payload.room] = {
        ...state.allMessages[action.payload.room],
        viewed: [...state.allMessages[action.payload.room].viewed!, action.payload.message],
      };
    },
    addUnviewedMessage: (state, action: PayloadAction<string>) => {
      if (!state.newMessages[action.payload]) {
        state.newMessages[action.payload] = 0;
      }
      // Если сообщение добавляется в выбранную в данный момент комнату, то оно не засчитывается
      if (action.payload === state.chosenRoom) {
        return state;
      }
      state.newMessages[action.payload] += 1;
    },
    clearRoomUnviewedMessages: (state, action: PayloadAction<string>) => {
      state.newMessages[action.payload] = 0;
    },
  },
});

export const { actions, reducer } = chatSlice;
