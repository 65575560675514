import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';

import { useAppSelector } from '../../../hooks/redux.hook';
import translate from '../../../helpers/translate';

import * as C from '../../ui/Styled';
import * as S from './styled';
import AvatarMenu from './AvatarMenu';
import InputWithSearch from '../../InputWithSearch';
import { theme } from '../../../styles/theme';
import MobileMenuBurger from './MobileMenuBurger/intex';

// ! import { IPage } from '../../../hooks/routes.hook';
import { IPage } from '../../../helpers/router';
interface IHeaderProps {
  mobileMenuCollapsed: boolean;
  setMobileMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  mobileMenuCloseHandler: () => void;
  setFastOrderDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Header({
  mobileMenuCollapsed,
  setMobileMenuCollapsed,
  mobileMenuCloseHandler,
  setFastOrderDrawerVisible,
}: IHeaderProps) {
  const location = useLocation();
  const isDefaultScreen = useMediaQuery(theme.media.default);
  const { role } = useAppSelector((state) => state.user.userData);

  const pagesWithSearchInput: IPage[] = [
    '/orders',
    '/ordercontents',
    '/products',
    '/partners',
    '/users',
    '/logger',
  ];

  const tooltipText = () => {
    switch (location.pathname as IPage) {
      case '/ordercontents':
        return 'Поиск по номеру счёта, клиенту, контрагенту, наименованию и артикулу';
      case '/orders':
        return 'Поиск по номеру счёта, клиенту, контрагенту или менеджеру';
      case '/users':
        return 'Поиск по Акспром ID, имени, фамилии и e-mail';
      case '/partners':
        return 'Поиск по id, Коду Акспром, Наименованию и ИНН';
      case '/logger':
        return 'Поиск по URL, Namespace, IP, сообщениям';
      default:
        return '';
    }
  };

  return (
    <S.Header>
      {isDefaultScreen ? (
        <>
          <C.Flex $width="70%" $align="center">
            {/* Название страницы */}
            <C.Title $fontWeight="600" $fontSize="25px" $margin="0 30px 0 4px">
              {translate().pageTranslate(location.pathname as IPage)}
            </C.Title>
            {/* Инпут поиска по строке (для определенных страниц) */}
            {pagesWithSearchInput.includes(location.pathname as IPage) && (
              <InputWithSearch
                tooltipText={tooltipText()}
                tooltipPlacement="topRight"
                inputWidth="60%"
              />
            )}
          </C.Flex>
          <C.Flex>
            {/* Кнопка быстрого заказа для покупателя */}
            {role === 'customer' && (
              <Button
                type="primary"
                shape="round"
                onClick={() => setFastOrderDrawerVisible(true)}
                style={{ marginRight: '20px' }}
              >
                Быстрый заказ
              </Button>
            )}
            {/* Аватар и выпадающее меню */}
            <AvatarMenu />
          </C.Flex>
        </>
      ) : (
        <>
          <MobileMenuBurger
            mobileMenuCollapsed={mobileMenuCollapsed}
            setMobileMenuCollapsed={setMobileMenuCollapsed}
            mobileMenuCloseHandler={mobileMenuCloseHandler}
          />
          {/* Кнопка быстрого заказа для покупателя */}
          {role === 'customer' && (
            <Button type="primary" shape="round" onClick={() => setFastOrderDrawerVisible(true)}>
              Быстрый заказ
            </Button>
          )}
          {/* Аватар и выпадающее меню */}
          <AvatarMenu />
        </>
      )}
    </S.Header>
  );
}
