import styled from 'styled-components';

export const InputArea = styled.input`
  border: none;
  padding: 0 10px;
  width: 80%;
  &:focus {
    outline: 0;
  }
`;
