import { useState } from 'react';

interface ICoords {
  x: number;
  y: number;
}

export default function useSwipe() {
  // Координаты первого касания
  const [firstTouchCoords, setFirstTouchCoords] = useState<ICoords>({} as ICoords);
  // Текущие координаты свайпа
  const [touchMoveCoords, setTouchMoveCoords] = useState<ICoords>({} as ICoords);
  // Направление свайпа ('left', 'right', 'up', 'down')
  const [swipeDirection, setSwipeDirection] = useState<string>('');
  // Стейт окончания свайпа
  const [touchEnd, setTouchEnd] = useState<boolean>(false);
  // Смещение по Y в пикселах (используется для изменения высоты окна)
  const yOffset: number = Math.round(firstTouchCoords.y - touchMoveCoords.y);

  // Запись в стейт координатов первого касания
  const handleTouchStart = (e: React.TouchEvent): void => {
    // Убираем всплытие на родительском дроере
    e.stopPropagation();
    // При первом касании записываем в стейт его координаты и сбрасываем все остальные стейты (т.к. это новый свайп)
    const firstTouch = e.touches[0];
    setFirstTouchCoords({ x: firstTouch.clientX, y: firstTouch.clientY });

    setTouchMoveCoords({} as ICoords);
    setSwipeDirection('');
    setTouchEnd(false);
  };

  // Обработка свайпа касания
  const handleTouchMove = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setTouchMoveCoords({ x: touch.clientX, y: touch.clientY });

    // Определние направления свайпа
    checkSwipeDirection();
  };

  // Определение направления свайпа
  const checkSwipeDirection = () => {
    // Отсутствие свайпа
    if (!touchMoveCoords.x && !touchMoveCoords.y) {
      return;
    }

    // Разница между текущей координатой и координатой первого касания
    const xDiff: number = touchMoveCoords.x - firstTouchCoords.x;
    const yDiff: number = touchMoveCoords.y - firstTouchCoords.y;

    // Если смещение по горизонтали больше, чем по вертикали, то свайп либо влево, либо вправо
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      xDiff > 0 ? setSwipeDirection('right') : setSwipeDirection('left');
      // Иначе свайп вниз/вверх
    } else {
      yDiff > 0 ? setSwipeDirection('down') : setSwipeDirection('up');
    }
  };

  // При окончании свайпа touchEnd переходит в true. Т.о. отслеживается окончание действия
  const handleTouchEnd = () => {
    setTouchEnd(true);
  };

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    swipeDirection,
    yOffset,
    touchEnd,
  };
}
