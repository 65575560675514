import './index.scss';

interface ILoader {
  page?: string;
}

export default function Loader({ page }: ILoader) {
  return (
    <div className="lds-ring m-b-20" style={{ display: 'block', margin: 'auto' }}>
      <div className={page}></div>
      <div className={page}></div>
      <div className={page}></div>
      <div className={page}></div>
    </div>
  );
}
