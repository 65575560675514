import { Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { useAppSelector } from '../../../../hooks/redux.hook';

import * as S from './styled';
import DropdownMenu from './DropdownMenu';

// Аватар и выпадающее меню
export default function AvatarMenu() {
  const { firstname } = useAppSelector((state) => state.user.userData);

  return (
    <Dropdown overlay={<DropdownMenu />} trigger={['click']}>
      <S.Avatar>
        <UserOutlined />
        {firstname}
      </S.Avatar>
    </Dropdown>
  );
}
