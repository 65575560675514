import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
* {
  font-family: 'Roboto', sans-serif;
}

// Добавление указателя к курсору
.pointer {
  cursor: pointer;
}

// Отмена скролла (используется в body) для предотвращения скролла заднего фона при появлении модалки
.scroll-cancel {
  touch-action: none;
  overflow: hidden;
}

/* Отступы и высота строк для таблицы с выбором отображаемых полей */
.fields-table {
  height: 25px;
  margin-bottom: 7px;
}

// Дополнительные стили дроера чата (применяются за счет добавления класса chat-drawer в Chat/ChatDrawer) ------------------
.chat-drawer {
  .ant-drawer-title {
    color: rgba(255, 255, 255, 0.65);
    font-weight: 400;
  }
  .ant-drawer-close {
    padding: 11px !important;
  }
  .ant-drawer-content {
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  }
}
.chat-drawer-mobile {
  .ant-drawer-header {
    padding-left: 15px !important;
  }
  .ant-drawer-title {
    color: rgba(255, 255, 255, 0.65);
    font-weight: 400;
    font-size: 12px;
  }
  .ant-drawer-close {
    padding: 11px !important;
  }
  .ant-drawer-content {
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  }
}

// Стили для ScrollToBottom в чате (Chat/Messages) ------------------- 
.messages-wrapper {
    position: relative;
    width: 100%;
    overflow: auto;
    flex: auto;
    padding-bottom: 25px;
    padding-left: 10px;
    border-bottom: 1px solid gray;
}
.scrollView {
    padding-right: 10px;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #f9f9fd;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #001529;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background-color: #f9f9fd;
    }
}

// Стили для таблиц SyncFusion ---------------------------------------------- 
/* Кружки пагинации */
.e-grid .e-gridpager .e-currentitem {
  background-color: #1890ff;
}

/* Кнопка применить в выборе столбцов */
.e-control.e-btn.e-lib.e-cc.e-cc_okbtn.e-primary.e-flat {
  color: #1890ff;
}

/* Кнопки в мобильном режиме сортировки */
.e-control.e-btn.e-lib.e-primary.e-flat.e-res-apply-btn {
  color: #1890ff;
}

/* Чекбоксы в выборе отображаемых столбцов */
.e-frame.e-icons.e-check {
  background-color: #1890ff !important;
}

/* Заголовок таблицы (для десктопа) */
.e-grid .e-headercell.syncGridHeader {
  padding: 15px !important;
}
.e-grid .e-headercelldiv {
  text-align: center;
  padding: 0 !important;
}

/* Строки таблицы (для мобильного вида) */
.e-grid .e-rowcell.syncGridMobile {
  display: flex !important;
  align-items: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 25px !important;
  height: auto !important;
  word-break: break-word;
}
.e-bigger .e-grid.e-row-responsive .e-gridcontent td::before {
  top: auto !important;
}

/* Стрелка фильтрации в заголовке табилцы */
.e-grid .e-sortfilterdiv {
  margin: -17px -10px !important;
  color: ${theme.colors.line.main} !important
}

// Паддинг под списком для мобильной версии (для отделения заказов/товаров)
.e-grid .e-row {
  padding: 15px 0;
}

/* Цвет четных рядов таблицы */
.e-grid .e-altrow {
    background-color: #fafafa;
}

/* Ячейки таблицы */
.syncGridCell {
    padding: 10px !important;
    line-height: 14px !important;
    text-align: center;
    height: 34px !important;
}

// Ручная подстановка стилей для отключения ej2-inputs (экономия места)
.e-pagerdropdown {
    height: 50px !important;
    .e-input-group.e-alldrop {
        display: inline-flex;
        margin-bottom: 4px;
        border-bottom: 1px solid gray;
        input {
            box-sizing: content-box;
            min-height: 27px;
            border: 0 solid;
            margin: 0 0 4px;
            outline: none;
            width: 100%;
            padding: 5px 0;
            font-size: 14px;
        }
        .e-input-group-icon.e-ddl-icon.e-search-icon {
            margin-bottom: 5px;
            margin-top: 6px;
        }
    }
}

/* Цвет спиннера таблицы */
.e-spin-material {
  stroke: ${theme.colors.loader} !important;
}

// Ручная подстановка стилей для отключения ej2-buttons (экономия места)
.e-tbar-btn.e-tbtn-txt.e-control.e-btn.e-lib {
    width: 70px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    background: #fafafa;
    &:hover {
        background: gray;
    }
}

// Разные стили для AntDesign --------------------------------- 
// Отключение взаимодействия с элементами формы в AntDesign (необходимо добавить className="readOnly" к любому элементу формы)
.ant-input.readOnly {
  pointer-events: none;
}

// К селекту применяется cursor:pointer, если задать в нем пропс className="pointer"
.ant-select.pointer {
  .ant-select-selector {
    cursor: pointer !important;
  }
}

// Уменьшение паддингов в мобильном Логгере
.ant-list-sm .ant-list-item {
  padding: 8px 8px !important;
}
`;
