import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getFingerprint from '../../helpers/getFingerprint';
import { ActionCreators } from '../../redux/store';
import { messagesAndErrorsHandling } from './messagesAndErrorsHandling';
import { userLogoutHandler } from './helper';

import { ILogin } from '../../types/user';

// Базовый запрос
export const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: (headers, { getState }) => {
    headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return headers;
  },
  credentials: 'include', // Позволяет серверу использовать cookies
});

// Запрос с обновлением токена
export const baseQueryWithRefreshToken: BaseQueryFn = async (args, api, extraOptions) => {
  // ! Если удалять сообщения здесь, то при любом запросе, после которго обновляется таблица, сообщение сразу же пропадает
  // api.dispatch(ActionCreators.hideMessage());
  api.dispatch(ActionCreators.showLoader());

  // Отправка базового запроса
  let result: { data?: any; error?: any } = await baseQuery(args, api, extraOptions);

  // Вывод сообщений в соответствии с результатом
  messagesAndErrorsHandling(result, args, api);

  // Интерцептор (в случае наличия ошибки). Запрос на refreshtokens не перехватывается, т.к. если он не обработан в первый раз, то в этом нет смысла
  if (result.error && result.error.status === 401 && !args.url.includes('refreshtokens')) {
    const fingerprint = await getFingerprint();

    // Обновление токенов
    const refreshResult = await baseQuery(
      {
        url: `/auth/refreshtokens/${fingerprint}`,
        method: 'GET',
      },
      api,
      extraOptions
    );
    // Новые данные пользователя с новым accessToken
    const newUserData = refreshResult.data as ILogin;

    // Если при обновлении токенов возникла ошибка, разлогиниваем пользователя
    if ('error' in refreshResult) {
      userLogoutHandler(api);
    }
    // Если токен есть, то он записывается в LocalStorage, а базовый запрос повторяется с новым токеном
    if (newUserData.accessToken) {
      localStorage.setItem('token', newUserData.accessToken);
      result = await baseQuery(args, api, extraOptions);
    }
  }

  api.dispatch(ActionCreators.hideLoader());
  return result;
};

// ! Токен с истекшим сроком для теста
//   `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQsInVzZXJSb2xlIjoiYWRtaW4iLCJ0eXBlIjoiYWNjZXNzIiwiaWF0IjoxNjQzMDA5OTU4LCJleHAiOjE2NDMwMTE3NTh9.PWPvdFJT3o2OTksjE9OAMOZpBssoQDof06mTBvZgCkc`
