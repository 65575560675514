import ScrollToBottom from 'react-scroll-to-bottom';

import * as C from '../../ui/Styled';
import Message from '../Message';

import { useAppSelector } from '../../../hooks/redux.hook';

import { IUserTyping } from '../../../types/chat';
interface IMessages {
  room: string;
  akspromUserId: string;
  userTyping: IUserTyping;
}

export default function Messages({ room, akspromUserId, userTyping }: IMessages) {
  const { allMessages } = useAppSelector((state) => state.chat);

  return (
    // Без класс не поставить ширину 100%. Класс scroll-view для задания стилей полосы скроллбару
    <ScrollToBottom className="messages-wrapper" scrollViewClassName="scrollView">
      {allMessages[room]?.viewed?.map((message, i) => (
        <div key={i}>
          <Message message={message} akspromUserId={akspromUserId} />
        </div>
      ))}

      {/* Непрочитанные сообщения */}
      {allMessages[room]?.notViewed?.length ? (
        <>
          <C.Title $padding="15px 0" $textAlign="center">
            Непрочитанные сообщения:
          </C.Title>
          {allMessages[room]?.notViewed?.map((message, i) => (
            <div key={i}>
              <Message message={message} akspromUserId={akspromUserId} />
            </div>
          ))}
          <hr />
        </>
      ) : null}

      {/* Новые сообщения (добавленные после непрочитанных) */}
      {allMessages[room]?.afterNotViewed?.length
        ? allMessages[room]?.afterNotViewed?.map((message, i) => (
            <div key={i}>
              <Message message={message} akspromUserId={akspromUserId} />
            </div>
          ))
        : null}

      {/* Сообщение о печатании. Если это не тот же пользователь который печатает, а также если это именно та комната (т.к. иначе при переключении комнаты менеджером он будет получать сообщения о печатании из всех комнат) */}
      {userTyping.akspromUserId !== akspromUserId &&
      userTyping.typing &&
      userTyping.room === room ? (
        <C.Wrapper $position="absolute" $bottom="0" $top="10px">
          {userTyping.firstname} печатает..
        </C.Wrapper>
      ) : null}
    </ScrollToBottom>
  );
}
