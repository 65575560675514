import { Suspense } from 'react';

import * as C from '../Styled';
import Loader from '../Loader';

import { WithChildren } from '../../../types/childern';
import Logo from '../Logo';

interface SuspenseWrapperProps extends WithChildren {
  fallback?: 'loader' | 'logo';
}

export default function SuspenseWrapper({ children, fallback = 'loader' }: SuspenseWrapperProps) {
  const fallbackContent = () => {
    switch (fallback) {
      case 'loader':
        return (
          <C.Flex $center $height="100%" $width="100%">
            <Loader />
          </C.Flex>
        );
      case 'logo':
        return (
          <C.Flex $center $height="100vh">
            <Logo type={'mainWithoutTextAnimated'} />
          </C.Flex>
        );
      default:
        return <></>;
    }
  };

  return <Suspense fallback={fallbackContent()}>{children}</Suspense>;
}
