import styled from 'styled-components';

interface PageLayoutProps {
  $isDefaultScreen: boolean;
}

export const PagesLayout = styled.div<PageLayoutProps>`
  /* min-height: 360px; */
  height: 100%;
  padding: ${({ $isDefaultScreen }) => ($isDefaultScreen ? '25px' : '5px')};
`;
