import { useEffect } from 'react';
import { Drawer } from 'antd';
import { useMediaQuery } from 'react-responsive';

import useSwipe from '../../../hooks/swipe.hook';
import { theme } from '../../../styles/theme';

interface IDrawerWithSwipeProps extends React.ComponentProps<typeof Drawer> {
  setDrawerVisible: (visibility: boolean) => void;
}

const DrawerWithSwipe = ({ setDrawerVisible, ...props }: IDrawerWithSwipeProps) => {
  const isDefaultScreen = useMediaQuery(theme.media.default);
  // ------------ Обработка свайпа на дроере (для мобильной версии) --------------
  // Хук для работы со свайпом
  const { handleTouchStart, handleTouchMove, handleTouchEnd, swipeDirection } = useSwipe();

  // Закрытие дроера при свайпе вправо
  useEffect(() => {
    if (swipeDirection === 'right') {
      // Отмена запрета скролла подложки (избежание бага в мобильной версии)
      document.body.classList.remove('scroll-cancel');
      setDrawerVisible(false);
    }
  }, [swipeDirection]); // eslint-disable-line react-hooks/exhaustive-deps

  // ------------------------------------------------------------------------
  return (
    // В мобильном режиме маска отключается для избежания ошибок, связанных с тач-событиями (косяк antDesign)
    <Drawer {...props} mask={isDefaultScreen ? true : false}>
      {/*! Убрать div? */}
      <div
        className="touch" // Обработка закрытия дроера по свайпу
        style={{ height: '100%' }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {props.children}
      </div>
    </Drawer>
  );
};

export default DrawerWithSwipe;
