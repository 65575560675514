import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  SettingOutlined,
  DatabaseOutlined,
  ToolOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { nanoid } from 'nanoid';

import { useActions, useAppSelector } from '../../../hooks/redux.hook';

// Используется в десктопной версии в сайдбаре, а также в мобильном меню
export default function MainMenu(props: React.ComponentProps<typeof Menu>) {
  const { role } = useAppSelector((state) => state.user.userData);
  const { showChatDrawer } = useActions();

  return (
    <Menu {...props}>
      <Menu.SubMenu title="Заказы" key={nanoid()} icon={<DatabaseOutlined />}>
        <Menu.Item key={nanoid()}>
          <Link to={'/orders'}>Заказы</Link>
        </Menu.Item>
        <Menu.Item key={nanoid()}>
          <Link to={'/ordercontents'}>Товары в заказах</Link>
        </Menu.Item>
      </Menu.SubMenu>

      {role !== 'customer' ? (
        <>
          <Menu.SubMenu key={nanoid()} icon={<SettingOutlined />} title="Управление">
            <Menu.Item key={nanoid()}>
              <Link to={'/partners'}>Контрагенты</Link>
            </Menu.Item>
            <Menu.Item key={nanoid()}>
              <Link to={'/users'}>Пользователи</Link>
            </Menu.Item>
            <Menu.Item key={nanoid()}>
              <Link to={'/roles'}>Роли</Link>
            </Menu.Item>
            <Menu.Item key={nanoid()}>
              <Link to={'/fields'}>Поля</Link>
            </Menu.Item>
            <Menu.Item key={nanoid()}>
              <Link to={'/mail'}>Почта</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key={nanoid()} icon={<ToolOutlined />} title="В разработке">
            <Menu.Item key={nanoid()}>
              <Link to={'/logger'}>Логгер</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </>
      ) : null}

      <Menu.Item key={nanoid()} icon={<MessageOutlined />} onClick={() => showChatDrawer()}>
        Чат
      </Menu.Item>
    </Menu>
  );
}
