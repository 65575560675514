import { useDropzone, FileWithPath } from 'react-dropzone';
import { useMediaQuery } from 'react-responsive';

import * as C from '../Styled';
import * as S from './styles';
import { theme } from '../../../styles/theme';

interface IDropzoneProps {
  setFiles: (files: any[]) => void;
  files: FileWithPath[] /* интерфейс файла импортируется из react-dropzone */;
  single?: boolean;
  height?: number;
}

export default function Dropzone({ setFiles, files, single = true, height }: IDropzoneProps) {
  // Адаптивность
  const isDefaultScreen = useMediaQuery(theme.media.default);
  // Проверка количества помещаемых файлов. По умолчанию несколько файлов добавляется только в том случае, если выделить их мышью и поместить на область. Если добавлять по одному, то каждый последующий заменяет предыдущий. Данный код позволяет добавлять любое количество файлов любым способом.
  // пропс `quantity` позволяет контролировать количество загружаемых файлов. По умолчанию можно загружать только один (каждый последующий будет заменять предыдущий)
  const onDrop = (acceptedFiles: any[]) => {
    // Если файл один, то он всегда заменяет предыдущий
    if (single) {
      setFiles([acceptedFiles[0]]);
      return;
    }

    // Для нескольких файлов создаем новый массив. Затем проверяем каждый файл из добавляемых на совпадение с теми, что уже лежат в дропзоне. Те, которых еще нет, добавляются в этот массив. Новый массив добавляется к уже лежащим в дропзоне файлам
    let newFilesArray = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (
        !files.find((file) => {
          return file.path === acceptedFiles[i].path;
        })
      ) {
        newFilesArray.push(acceptedFiles[i]);
      }
    }
    setFiles([...files, ...newFilesArray]);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  const onDeleteFileHandler = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    file: FileWithPath
  ): void => {
    const newFileArray = files.filter((f) => f !== file);
    setFiles([...newFileArray]);
  };

  // Форматирование размеров файлов
  function fileSizeFormat(fileSize: number): string {
    let i = 0;
    const type = ['б', 'Кб', 'Мб'];
    while ((fileSize / 1000) | 0 && i < type.length - 1) {
      fileSize /= 1024;
      i++;
    }
    return fileSize.toFixed(2) + ' ' + type[i];
  }

  // отображение файлов под дропзоной
  const filesInfo = files.map((file) => {
    return (
      <li key={file.name}>
        {file.name} - {fileSizeFormat(file.size)} -
        <S.DeleteFileSpan onClick={(e) => onDeleteFileHandler(e, file)}>Удалить</S.DeleteFileSpan>
      </li>
    );
  });

  return (
    <section style={height ? { height: `${height}px`, cursor: 'pointer' } : { cursor: 'pointer' }}>
      <S.DropzoneContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>
          {isDefaultScreen
            ? single
              ? 'Перетащите файл сюда или кликните для выбора файла в Проводнике'
              : 'Перетащите файлы сюда или кликните для выбора файлов в Проводнике'
            : single
            ? 'Нажмите для выбора файла или включения камеры'
            : 'Нажмите для выбора файлов или включения камеры'}
        </p>
      </S.DropzoneContainer>
      {filesInfo.length ? (
        <C.Wrapper $margin="10px 0 0">
          <C.Title>{single ? 'Прикрепленный файл:' : 'Прикрепленные файлы:'}</C.Title>
          <ul>{filesInfo}</ul>
        </C.Wrapper>
      ) : null}
    </section>
  );
}
