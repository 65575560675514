import { createAsyncThunk } from '@reduxjs/toolkit';

import { ILogin } from '../../types/user';
import { showMessage } from './appActions';
import { ActionCreators } from '../store';
const {
  userSetDataAndAuth,
  userSetInitialState,
  loadAllColumns,
  loadDefautColumns,
  hideChatDrawer,
} = ActionCreators;

// Функции запроса на сервер

// Авторизация пользователя
export const userLogin = createAsyncThunk(
  'user/login',
  async (userLoginData: ILogin, { dispatch }) => {
    // Проверка на активацию
    if (!userLoginData.user.isActivated) {
      dispatch(
        showMessage({
          text: 'Аккаунт не активирован. Проверьте почту!',
          type: 'danger',
        })
      );
    }

    // Запись accessToken в LocalStorage
    localStorage.setItem('token', userLoginData.accessToken);

    // При успешной авторизации сервер отправляет список всех столбцов, а также видимых пользователю
    dispatch(loadAllColumns(userLoginData.colNames.all));
    dispatch(loadDefautColumns(userLoginData.colNames.default));

    dispatch(userSetDataAndAuth(userLoginData.user));
  }
);

// Выход пользователя
export const userLogout = createAsyncThunk('user/logout', async (_, { dispatch }) => {
  // Удаление refreshToken из LocalStorage
  localStorage.removeItem('token');

  // Удаление данных о пользователе из стора, перевод параметра isAuth в false
  dispatch(userSetInitialState());

  // Скрытие окна чата
  dispatch(hideChatDrawer());
});
